import { useState } from 'react';
import { IUseFormResult } from '../../../../Services/hooks/useForm';

import { Icon, Input, TextArea } from '../../../../Components';
import styles from './MainInfo.module.css';

interface IMainInfoProps {
  formData: IUseFormResult;
};

function MainInfo(props: IMainInfoProps) {
  const { values, errors, handleChange } = props.formData;
  const [isOpen, setOpen] = useState<boolean>(true);

  return (
    <section className={styles.container}>
      <div className={styles['title-container']} onClick={() => setOpen(isOpen => !isOpen)}>
        <h2 className={styles.title}>Основная информация</h2>
        <button className={`${styles['hide-button']} ${!isOpen && styles['reverse-button']}`}><Icon type="caret" /></button>
      </div>
      <div className={`${styles.inputs} ${!isOpen && styles['inputs-hide']}`}>
        <Input
          label="Фамилия"
          name="lastName"
          value={values.lastName}
          handleChange={handleChange}
          className={styles.lastname}
          placeholder="Введите фамилию"
          error={errors.lastNameError}
          required
        />
        <Input
          label="Имя"
          name="firstName"
          value={values.firstName}
          handleChange={handleChange}
          className={styles.firstname}
          placeholder="Введите имя"
          error={errors.firstNameError}
          required
        />
        <Input
          label="Отчество"
          name="middleName"
          value={values.middleName}
          handleChange={handleChange}
          className={styles.middlename}
          placeholder="Введите отчество"
          error={errors.middleNameError}
          required
        />
        <Input
          label="Дата рождения"
          name="birthday"
          value={values.birtday}
          handleChange={handleChange}
          className={styles.birthday}
          placeholder="Укажите дату"
          error={errors.birthdayError}
        />
        <Input
          label="Дата смерти"
          name="deathday"
          value={values.deathday}
          handleChange={handleChange}
          className={styles.deathday}
          placeholder="Укажите дату"
          error={errors.deathdayError}
        />
        <TextArea
          label="Описание"
          name="description"
          value={values.description}
          handleChange={handleChange}
          className={styles.textarea}
          placeholder="Добавьте текст"
          error={errors.descriptionError}
          required
        />
      </div>
    </section>
  );
}

export default MainInfo;
