import axios from '../axios';
import { TAxiosResult } from '../types/axios';
import { TFetchListParams, TPersonsListSizeResult, TPerson, TPersonRemoveResult, TPersonForCreate } from '../types/person';

class PersonsApi {
  static async fetchList(params?: TFetchListParams): Promise<TAxiosResult<TPerson[]>> {
    return await axios.get('/api/person/list', {
      params,
    })
      .catch(({ response }) => ({ ...response }));
  }

  static async fetchListSize(params?: TFetchListParams): Promise<TAxiosResult<TPersonsListSizeResult>> {
    return await axios.get('/api/person/listSize', {
      params,
    })
      .catch(({ response }) => ({ ...response }));
  };

  static async fetchPerson(_id: string): Promise<TAxiosResult<TPerson[]>> {
    return await axios.get(`/api/person/${_id}`)
      .catch(({ response }) => ({ ...response }));
  }

  static async removePerson(_id: string, token: string): Promise<TAxiosResult<TPersonRemoveResult>> {
    return await axios.delete(`/api/person/${_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({ ...response }));
  }

  static async createPerson(person: TPersonForCreate, token: string): Promise<TAxiosResult<TPerson>> { // Исправить возвращаемый тип
    return await axios.post('/api/person', person, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({ ...response }));
  }

  static async uploadAvatar(_id: string, img: any, token: string): Promise<TAxiosResult<any>> {
    const data = new FormData();
    data.append('image_person_main', img);

    return await axios.put(`/api/person/setMainImage/${_id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({ ...response }));
  }

  static async uploadPhoto(_id: string, img: File, description: string, number: string, token: string) {
    const data = new FormData();
    data.append('image_person_adv', img);
    data.append('text', description);

    return await axios.put(`/api/person/setGalleryItem/${_id}/${number}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({ ...response }));
  }

  static async editPerson(_id: string, token: string, mainInfo: { [key: string]: string }) {
    return await axios.put(`/api/person/${_id}`, mainInfo, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default PersonsApi;
