import axios from '../axios';
import { TAxiosResult } from '../types/axios';
import { TUser, TUserEdit, TFetchUserListParams, TUserListSizeResult, TUserRemoveResult, TUserCreate} from '../types/user'

class UserApi {
  static async fetchList( params?: TFetchUserListParams, token?: string): Promise<TAxiosResult<TUser[]>>{
    return await axios.get('/api/users/list', {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({...response}))
  }

  static async fetchListSize(params?: TFetchUserListParams, token?: string): Promise<TAxiosResult<TUserListSizeResult>> {
    return await axios.get('/api/users/listSize', {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({ ...response }));
  };

  static async removeUser(username: string, token: string): Promise<TAxiosResult<TUserRemoveResult>> {
    return await axios.delete(`/api/users/delete/${username}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async createUser(user: TUserCreate, token: string): Promise<TAxiosResult<TUserRemoveResult>> {
    return await axios.post(`/api/auth/signup/`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async getUserByUsername(username: string, token: string | null): Promise<any> {
    return await axios.get(`/api/users/getByUsername/${username}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async editUser(user: TUserEdit, username:string, token: string): Promise<TAxiosResult<TUser>> {
    return await axios.put(`/api/users/edit/${username}`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }


}

export default UserApi