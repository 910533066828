import { memo } from 'react';

import Icon from '../Icon/Icon';
import styles from './Modal.module.css';

interface IModalProps {
  close: () => void;
  title: string;
  children: JSX.Element | JSX.Element[] | string | string[] | null;
  modalClassName?: string;
}

function Modal(props: IModalProps) {
  const { close, title, children, modalClassName } = props;

  return (
    <div className={styles.overlay} onClick={close}>
      <div className={`${styles.modal} ${modalClassName}`} onClick={(event) => event.stopPropagation()}>
        <button className={styles['close-button']} onClick={close}>
          <Icon type="close" />
        </button>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default memo(Modal);
