import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Services/hooks/useStore';
import EmailValidator from 'email-validator';

import { useAuth, useForm, } from '../../Services/hooks';
import { userSelector, createEnd } from '../../Services/slices/users'
import { TUserCreate } from '../../types/user';
import createUser from '../../Services/thunks/createUser';

import MainInfo from './Components/MainInfo';
import { Layout, Button, Modal } from '../../Components';
import styles from './UserAdd.module.css';

function UserList(){
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(userSelector);
  const [modalData, setModalData] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  
  //modal succes
  const navigate = useNavigate();
  
  useEffect(()=>{
    if(status.isSuccesCreate) setModalData('Пункт успешно добвален');
  },[status.isSuccesCreate])
  
  const closeModal = () => {
    setModalData(null);
    dispatch(createEnd())
    navigate('/user')
  };
  
  const mainInfoFormData = useForm({
    firstName: '',
    middleName: '',
    lastName: '',
    password: '',
    username: '',
  }, {
    firstNameError: '',
    middleNameError: '',
    lastNameError: '',
    passwordError: '',
    usernameError: '',
  });
  const saveUser = useCallback(()=>{
    const keys = ['firstName','middleName','lastName','username','password']
    let errors:any = {};
    for(let key of keys){ errors[key] = '' }
    const { values: mainValues, setErrors: setMainErrors } = mainInfoFormData;

    let isErrors = false
    for(let key of keys){
      if (!mainValues[key]){ 
        errors[key+'Error']= 'Необходимо заполнить все обязательные поля';
        isErrors = true
      }
    }
    if (!EmailValidator.validate(mainValues.username)) {
      errors.usernameError = 'Введите валидный Email';
    }
    if(mainValues.password.includes(' ')){
      errors.passwordError = 'Пароль не должен соделражть пробелов';
    }
    if(mainValues.password.length < 6){
      errors.passwordError = 'Минимальная длина 6 символов';
    }
    if (role === null) {
      errors.roleError = 'Выберите роль';
    }


    if (isErrors){ return setMainErrors(errors) }

    const user: TUserCreate = {
      name:{
        first: mainInfoFormData.values.firstName,
        middle: mainInfoFormData.values.middleName,
        last: mainInfoFormData.values.lastName,
      },
      username: mainInfoFormData.values.username,
      password: mainInfoFormData.values.password,
      role: role || 'admin',
    }
    

    dispatch(createUser( user ))

  }, [mainInfoFormData, dispatch, role])
  
  const formData:any = {
    mainInfoFormData,
  }

  return (
  <Layout title="Добавление пользователя" user={user}
    headerContent={<div className={
      styles['header-content']}>
        <Button
          color="primary"
          size="large"
          className={styles['header-button']}
          onClick={saveUser}
        >
          <span>
            Добавить пункт
          </span>
        </Button>
        <Link to="/user">
          <Button color="red" size="large">Отменить</Button>
        </Link>
        
      </div>
    }
  >
    <MainInfo
      mainInfoFormData={mainInfoFormData}
      role={role}
      setRole={setRole}
    />
    {
      modalData ?
      <Modal
        close={closeModal}
        title="Cоздание пользователя"
      >
        <div className={styles['modal-content']}>
          <div className={styles['modal-text']}>
            <p>
              Пользователь успешно создан
            </p>
          </div>
          <div className={styles['modal-buttons']}>
            <Button onClick={closeModal} size="large">Продолжить</Button>
          </div>
        </div>
      </Modal>
      : <></>
    }
    </Layout>
  )
}

export default UserList