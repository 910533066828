import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import LiteratureApi from '../../API/LiteratureApi';
import { fetchList, fetchListSuccess, fetchListFail, fetchListSize, fetchListSizeSuccess, fetchListSizeFail } from '../slices/literatures';

function fetchLiteratureList(skip: number, limit:number, title?: string, sort?: string):  ThunkAction<void, TRootStore, unknown, AnyAction>{
  return async dispatch => {
    dispatch(fetchList());
    dispatch(fetchListSize());

    try {
      const params = {
        skip, limit,
        ...(title && { title }),
        ...(sort && { sort })
      };

      const result = await LiteratureApi.fetchList(params);

      if (result.status === 200) {
        dispatch(fetchListSuccess(result.data));
      }

    } catch (error) {
      dispatch(fetchListFail('Ошибка загрузки данных'));
    }

    try {
      const params = {
        ...(title && { title }),
      };
      
      const result = await LiteratureApi.fetchListSize(params);

      if (result.status === 200) {
        dispatch(fetchListSizeSuccess(result.data));
      }
    } catch(error) {
      dispatch(fetchListSizeFail('Ошибка загрузки данных'));
    }
  }
}

export default fetchLiteratureList;