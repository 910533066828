import { TAuthProfile } from '../../../../types/auth';

import styles from './Header.module.css';

interface IHeaderProps {
  title: string;
  user: TAuthProfile;
  content?: JSX.Element | JSX.Element[];
}

function Header(props: IHeaderProps) {
  const { title, user, content } = props;

  return (
    <header className={styles.header}>
      <h1 className={styles.title}>{title}</h1>
      {
        content ? content : null
      }
      <div className={styles['user-container']}>
        <span className={styles.role}>{user.role}</span>
        <div className={styles.delimiter} />
        <span className={styles.name}>
          {user.name.first} {user.name.middle} {user.name.last} 
        </span>
      </div>
    </header>
  )
}

export default Header;
