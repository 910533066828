import { Link, useMatch } from 'react-router-dom';
import { useAppDispatch } from '../../../../Services/hooks/useStore';
import { logout } from '../../../../Services/slices/auth';

import Icon from '../../../Icon/Icon';
import styles from './Nav.module.css';

function Nav() {
  const personListMatch = useMatch('/');
  const personAddMatch = useMatch('/person/add');
  const literatureMatch = useMatch('/literature');
  const regaliaMatch = useMatch('/regalia');

  const dispatch = useAppDispatch();

  const handlerLogout = () => {
    dispatch(logout());
    localStorage.removeItem('token');
  };

  return (
    <nav className={styles.nav}>
        <Icon type="logo" className={styles.logo} />
        <h2 className={styles.title}>Панель<br />администратора</h2>
        <ul className={styles.list}>
          <li>
            <Link to="/" className={`${styles.link} ${personListMatch ? styles.active : ''}`}>
              <Icon type="persons" className={styles['nav-icon']} />
              Общий список персон
            </Link>
          </li>
          <li>
            <Link to="/person/add" className={`${styles.link} ${personAddMatch ? styles.active : ''}`}>
              <Icon type="add" className={styles['nav-icon']} />
              Добавление персоны
            </Link>
          </li>
          <li>
            <Link to="/literature" className={`${styles.link} ${literatureMatch ? styles.active : ''}`}>
              <Icon type="document" className={styles['nav-icon']} />
              Список литературы
            </Link>
          </li>
          {/* <li>
            <Link to="/regalia" className={`${styles.link} ${regaliaMatch ? styles.active : ''}`}>
              <Icon type="university" className={styles['nav-icon']} />
              Список регалий
            </Link>
          </li> */}
          <li>
            <Link to="/user" className={`${styles.link} ${regaliaMatch ? styles.active : ''}`}>
              <Icon type="university" className={styles['nav-icon']} />
              Список пользователей
            </Link>
          </li>
        </ul>
        <button className={`${styles['logout-button']} ${styles.link}`} onClick={handlerLogout}>
          <Icon type="logout" className={styles['nav-icon']} />
          Выход
        </button>
      </nav>
  );
}

export default Nav;
