import { memo } from 'react';

import styles from './Button.module.css';

interface IButtonProps {
  className?: string;
  children: string | JSX.Element | JSX.Element[];
  onClick?: () => void;
  type?: 'button' | 'submit';
  color?: 'primary' | 'black' | 'red';
  size?: 'large' | 'small';
  disabled?: boolean;
}

function Button(props: IButtonProps) {
  const { className, children, onClick, type, color, size, disabled } = props;
  const buttonColor = color ? color : 'primary';
  const buttonSize = size ? size : 'small';

  return (
    <button
      className={`${styles.button} ${styles[buttonColor]} ${styles[buttonSize]} ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default memo(Button);
