import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootStore } from "../../store";
import { TPerson, TPersonsListSizeResult } from '../../types/person';

interface IPersonsState {
  list: TPerson[] | null;
  person: TPerson | null;
  size: number | null;
  loading: {
    list: boolean;
    size: boolean;
    remove: boolean;
    create: boolean;
    person: boolean;
    edit: boolean;
  };
  status:{
    isSuccesCreate: boolean;
    isSuccessEdit: boolean;
  };
  errors: {
    list: string;
    size: string;
    remove: string;
    create: string;
    person: string;
    edit: string;
  };
}

const initialState: IPersonsState = {
  list: null,
  person: null,
  size: null,
  loading: {
    list: false,
    size: false,
    remove: false,
    create: false,
    person: false,
    edit: false,
  },
  status:{
    isSuccesCreate: false,
    isSuccessEdit: false,
  },
  errors: {
    list: '',
    size: '',
    remove: '',
    create: '',
    person: '',
    edit: '',
  }
};

export const personsSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: {
    fetchList: (state) => {
      state.loading = {
        ...state.loading,
        list: true,
      };
      state.errors = {
        ...state.errors,
        list: '',
      };
    },
    fetchListSuccess: (state, action: PayloadAction<TPerson[]>) => {
      state.loading = {
        ...state.loading,
        list: false,
      };
      state.list = action.payload;
    },
    fetchListFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        list: action.payload,
      };
      state.loading = {
        ...state.loading,
        list: false,
      };
    },
    fetchListSize: (state) => {
      state.loading = {
        ...state.loading,
        size: true,
      };
      state.errors = {
        ...state.errors,
        size: '',
      };
    },
    fetchListSizeSuccess: (state, action: PayloadAction<TPersonsListSizeResult>) => {
      state.loading = {
        ...state.loading,
        size: false,
      };
      state.size = action.payload[0].n;
    },
    fetchListSizeFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        size: action.payload,
      };
      state.loading = {
        ...state.loading,
        size: false,
      };
    },
    fetchPerson: (state) => {
      state.loading = {
        ...state.loading,
        person: true,
      };
      state.errors = {
        ...state.errors,
        person: '',
      };
    },
    fetchPersonSuccess: (state, action: PayloadAction<TPerson[]>) => {
      state.loading = {
        ...state.loading,
        person: false,
      };
      state.person = action.payload[0];
    },
    fetchPersonFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        person: action.payload,
      };
      state.loading = {
        ...state.loading,
        person: false,
      };
    },
    clearPerson: (state) => {
      state.person = null;
    },
    remove: (state) => {
      state.loading = {
        ...state.loading,
        remove: true,
      };
      state.errors = {
        ...state.errors,
        remove: '',
      };
    },
    removeSuccess: (state) => {
      state.loading = {
        ...state.loading,
        remove: false,
      };
    },
    removeFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        remove: action.payload,
      };
      state.loading = {
        ...state.loading,
        remove: false,
      };
    },
    create: (state) => {
      state.loading = {
        ...state.loading,
        create: true,
      };
      state.errors = {
        ...state.errors,
        create: '',
      };
    },
    createSuccess: (state) => {
      state.loading = {
        ...state.loading,
        create: false,
      };
      state.status = {
        ...state.status,
        isSuccesCreate: true,
      };
    },
    createFail: (state, action: PayloadAction<string>) => {
      state.loading = {
        ...state.loading,
        create: false,
      };
      state.errors = {
        ...state.errors,
        create: action.payload,
      };
    },
    changeStatus: (state, action: PayloadAction<{field: string; value: boolean}>) => {
      state.status = {
        ...state.status,
        [action.payload.field]: action.payload.value,
      };
    },
    editPerson: (state) => {
      state.loading = {
        ...state.loading,
        edit: true,
      };
      state.errors = {
        ...state.errors,
        edit: '',
      };
    },
    editPersonSuccess: (state) => {
      state.loading = {
        ...state.loading,
        edit: false,
      };
      state.status = {
        ...state.status,
        isSuccessEdit: true,
      };
    },
    editPersonFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        edit: action.payload,
      };
      state.loading = {
        ...state.loading,
        edit: false,
      };
    },
  },
});

export const {
  fetchList,
  fetchListSuccess,
  fetchListFail,
  fetchListSize,
  fetchListSizeSuccess,
  fetchListSizeFail,
  fetchPerson,
  fetchPersonSuccess,
  fetchPersonFail,
  clearPerson,
  remove,
  removeSuccess,
  removeFail,
  create,
  createSuccess,
  createFail,
  changeStatus,
  editPerson,
  editPersonSuccess,
  editPersonFail,
} = personsSlice.actions;

export const personsSelector = (state: TRootStore) => state.persons;

export default personsSlice.reducer;
