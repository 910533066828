import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import UserApi from '../../API/UserApi';
import { editUser, editUserSuccess, editUserFail } from '../slices/users';
import { TUserEdit } from '../../types/user';

function editPersonThunk(username:string, user:TUserEdit): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(editUser());

    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await UserApi.editUser(user, username, token);
        dispatch(editUserSuccess());
      } catch(error) {
        dispatch(editUserFail('Ошибка редактирования персоны'));
      }
    }
  };
}

export default editPersonThunk;
