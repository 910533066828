import { memo, useState, FormEvent } from 'react';

import styles from './TextArea.module.css';

interface ITextAreaProps {
  className?: string;
  inputClassName?: string;
  label: string;
  value: string;
  handleChange: (event: FormEvent<HTMLTextAreaElement>) => void;
  name: string;
  error?: string;
  placeholder?: string;
  required?: boolean;
}

function TextArea(props: ITextAreaProps) {
  const {
    className,
    inputClassName,
    label,
    value,
    handleChange,
    name,
    error,
    placeholder,
    required,
  } = props;

  const [focus, setFocus] = useState<boolean>(false);

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  return (
    <label className={`${styles['container']} ${className}`}>
      <span className={styles.label}>{label} {required && <span className={styles.required}>*</span>}</span>
      <textarea
        className={`${styles.textarea} ${inputClassName} ${focus ? styles.focus : ''} ${error ? styles['error-border'] : ''}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        onChange={handleChange}
        name={name}
        placeholder={placeholder}
      ></textarea>
      {
        error &&
        <span className={styles['error-text']}>{error}</span>
      }
    </label>
  );
}

export default memo(TextArea);
