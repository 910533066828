import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import LiteratureApi from '../../API/LiteratureApi';
import { fetchEditLiterature, fetchEditLiteratureSuccess, fetchEditLiteratureFail, changeStatus } from '../slices/literatures';

function editLiterature(id: string, title: string, link: string, authors: string[], pdfFile: File | null): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetchEditLiterature());

    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await LiteratureApi.editLiterature(id, token, title, link, authors);
        
        if (result.status === 200) {

          if (pdfFile !== null) {
            try {
              await LiteratureApi.uploadPdfVersion(id, pdfFile, token);
            } catch(error) {
              
            }
          }

          dispatch(fetchEditLiteratureSuccess());
          dispatch(changeStatus({
            name: 'isSuccessEdit',
            value: true,
          }));
        } else {
          dispatch(fetchEditLiteratureFail('Ошибка обновления литературы'));
        }
      } catch(error) {
        dispatch(fetchEditLiteratureFail('Ошибка обновления литературы'));
      }
    }
  };
}

export default editLiterature;