import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import PersonsApi from '../../API/PersonsApi';
import { fetchList, fetchListSuccess, fetchListFail, fetchListSize, fetchListSizeSuccess, fetchListSizeFail } from '../slices/persons';

function fetchPersonsList(skip: number, limit: number, name?: string, filter?: string, sort?: string, letter?: string): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetchList());
    dispatch(fetchListSize());

    try {
      const params = {
        skip, limit,
        ...(name && { personName: name }),
        ...(filter && { yearsStatus: filter }),
        ...(sort && { sort }),
        ...(letter && { letter }),
      };

      const result = await PersonsApi.fetchList(params);

      if (result.status === 200) {
        dispatch(fetchListSuccess(result.data));
      }
    } catch(error) {
      dispatch(fetchListFail('Ошибка загрузки данных'));
    }

    try {
      const params = {
        ...(name && { personName: name }),
        ...(filter && { yearsStatus: filter }),
        ...(letter && { letter }),
      };
      console.log(params);
      
      const result = await PersonsApi.fetchListSize(params);

      if (result.status === 200) {
        dispatch(fetchListSizeSuccess(result.data));
      }
    } catch(error) {
      dispatch(fetchListSizeFail('Ошибка загрузки данных'));
    }
  }
}

export default fetchPersonsList;