import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootStore } from "../../store";
import { TLiterature, TLiteratureListSizeResult } from "../../types/literature";

interface ILiteratureState {
  list: TLiterature[] | null;
  size: number | null;
  loading: {
    list: boolean;
    size: boolean;
    remove: boolean;
    create: boolean;
    edit: boolean;
  };
  status: {
    isSuccesCreate: boolean;
    isSuccessEdit: boolean;
    mainLiterature: string;
    aboutLiterature: string;
    additionalyLiterature: string;
  };
  errors: {
    list: string;
    size: string;
    remove: string;
    create: string;
    edit: string;
  };
}

const initialState: ILiteratureState = {
  list: null,
  size: null,
  loading: {
    list: false,
    size: false,
    remove: false,
    create: false,
    edit: false,
  },
  status:{
    isSuccesCreate:false,
    isSuccessEdit: false,
    mainLiterature: '',
    aboutLiterature: '',
    additionalyLiterature: '',
  },
  errors: {
    list: '',
    size: '',
    remove: '',
    create: '',
    edit: '',
  },
}

export const literatureSlice = createSlice({
  name:"literature",
  initialState,
  reducers:{
    fetchList: state => {
      state.loading = {
        ...state.loading,
        list: true,
      };
      state.errors = {
        ...state.errors,
        list: '',
      };
    },
    fetchListSuccess: (state, action: PayloadAction<TLiterature[]>) => {
      state.loading = {
        ...state.loading,
        list: false,
      };
      state.list = action.payload;
    },
    fetchListFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        list: action.payload,
      };
      state.loading = {
        ...state.loading,
        list: false,
      };
    },
    fetchListSize: (state) => {
      state.loading = {
        ...state.loading,
        size: true,
      };
      state.errors = {
        ...state.errors,
        size: '',
      };
    },
    fetchListSizeSuccess: (state, action: PayloadAction<TLiteratureListSizeResult>) => {
      state.loading = {
        ...state.loading,
        size: false,
      };
      state.size = action.payload[0].n;
    },
    fetchListSizeFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        size: action.payload,
      };
      state.loading = {
        ...state.loading,
        size: false,
      };
    },
    remove: (state) => {
      state.loading = {
        ...state.loading,
        remove: true,
      };
      state.errors = {
        ...state.errors,
        remove: '',
      };
    },
    removeSuccess: (state) => {
      state.loading = {
        ...state.loading,
        remove: false,
      };
    },
    removeFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        remove: action.payload,
      };
      state.loading = {
        ...state.loading,
        remove: false,
      };
    },
    create: (state) => {
      state.loading = {
        ...state.loading,
        create: true,
      };
      state.status = {
        ...state.status,
        isSuccesCreate:false,
      };
      state.errors = {
        ...state.errors,
        create: '',
      };
    },
    createSuccess: (state) => {
      state.loading = {
        ...state.loading,
        create: false,
      };
      state.status = {
        ...state.status,
        isSuccesCreate:true,
      };
    },
    createEnd: (state) => {
      state.loading = {
        ...state.loading,
        create: false,
      };
      state.status = {
        ...state.status,
        isSuccesCreate:false,
      };
    },
    createFail: (state, action: PayloadAction<string>) => {
      state.loading = {
        ...state.loading,
        create: false,
      };
      state.errors = {
        ...state.errors,
        create: action.payload,
      };
    },
    fetchEditLiterature: (state) => {
      state.loading = {
        ...state.loading,
        edit: true,
      };
      state.errors = {
        ...state.errors,
        edit: '',
      }; 
    },
    fetchEditLiteratureSuccess: (state) => {
      state.loading = {
        ...state.loading,
        edit: false,
      };
    },
    fetchEditLiteratureFail: (state, action: PayloadAction<string>) => {
      state.loading = {
        ...state.loading,
        edit: false,
      };
      state.errors = {
        ...state.errors,
        edit: action.payload,
      }; 
    },
    changeStatus: (state, action: PayloadAction<{ name: string, value: boolean | string }>) => {
      state.status = {
        ...state.status,
        [action.payload.name]: action.payload.value,
      };
    },
  }
})

export const {
  fetchList,
  fetchListSuccess,
  fetchListFail,
  fetchListSize,
  fetchListSizeSuccess,
  fetchListSizeFail,
  remove,
  removeSuccess,
  removeFail,
  create,
  createSuccess,
  createFail,
  createEnd,
  fetchEditLiterature,
  fetchEditLiteratureSuccess,
  fetchEditLiteratureFail,
  changeStatus,
} = literatureSlice.actions;

export const literatureSelector = (state: TRootStore) => state.literature;

export default literatureSlice.reducer;