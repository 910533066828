import { useEffect } from "react";
import useAuth from "./useAuth";

function useInitialApp() {
  const token = localStorage.getItem('token');
  const { isAuth, getUserData } = useAuth();

  // Загрузка информации о пользователе
  useEffect(() => {
    if (token && !isAuth) {
      getUserData(token);
    }
  }, [token, isAuth, getUserData]);
}

export default useInitialApp;
