import { FormEvent, memo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Icon, TextArea } from '../../../../Components';
import styles from './MainText.module.css';

interface IMainTextProps {
  value: string;
  handleChange: (value: string) => void;
  // error: string;
}

function MainText(props: IMainTextProps) {
  const { value, handleChange } = props;
  const [isOpen, setOpen] = useState<boolean>(true);

  return (
    <section className={styles.container}>
      <div className={styles['title-container']} onClick={() => setOpen(isOpen => !isOpen)}>
        <h2 className={styles.title}>Основная информация</h2>
        <button className={`${styles['hide-button']} ${!isOpen && styles['reverse-button']}`}><Icon type="caret" /></button>
      </div>
      <div className={`${styles.inputs} ${!isOpen && styles['inputs-hide']}`}>
        <ReactQuill theme="snow" value={value} onChange={handleChange} className={styles['rich-text']} />
        {/* <TextArea
          label="Основной текст"
          required
          name="mainText"
          placeholder="Добавьте текст"
          value={value}
          handleChange={handleChange}
          error={error}
          inputClassName={styles.textarea}
        /> */}
      </div>
    </section>
  );
}

export default memo(MainText);
