import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import UserApi from '../../API/UserApi';
import { fetchList, fetchListSuccess, fetchListFail, fetchListSize, fetchListSizeSuccess, fetchListSizeFail } from '../slices/users';

function fetchUserList(skip: number, limit:number, title?: string):  ThunkAction<void, TRootStore, unknown, AnyAction>{
  return async dispatch => {
    dispatch(fetchList());
    dispatch(fetchListSize());

    try {
      const params = {
        skip, limit,
        ...(title && { name:title }),
      };
      
      const token = localStorage.getItem('token');
      const result = await UserApi.fetchList(params, token+'');

      if (result.status === 200) {
        dispatch(fetchListSuccess(result.data));
      }

    } catch (error) {
      dispatch(fetchListFail('Ошибка загрузки данных'));
    }

    try {
      const params = {
        skip, limit,
        ...(title && { title }),
      };
      const token = localStorage.getItem('token');
      const result = await UserApi.fetchListSize(params, token+'');

      if (result.status === 200) {
        dispatch(fetchListSizeSuccess(result.data));
      }
    } catch(error) {
      dispatch(fetchListSizeFail('Ошибка загрузки данных'));
    }
  }
}

export default fetchUserList;