import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import RegaliasApi from '../../API/RegaliasApi';
import { fetchList, fetchListSuccess, fetchListFail } from '../slices/regalias';

function fetchRegaliasList(): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetchList());

    try {
      const result = await RegaliasApi.fetchRegaliasList();

      if (result.status === 200) {
        dispatch(fetchListSuccess(result.data));
      }
    } catch(error) {
      dispatch(fetchListFail('Ошибка загрузки данных'));
    }
  }
}

export default fetchRegaliasList;