import { memo, useState, useCallback, FormEvent } from 'react';
import { TRegalia } from '../../../../types/regalia';
import { TOption } from '../../../../Components/Select/Select';

import { Select, Input, Icon, TextArea } from '../../../../Components';
import { TPersonRegalia } from '../../../../types/person';
import styles from './LidInfo.module.css';

interface ILidInfoProps {
  list: TPersonRegalia[];
  setRegalias: (regalias: TPersonRegalia[]) => void;
  regalias: TRegalia[];
  regaliaText: string;
  regaliasError: string;
  regaliaTextError: string;
  setRegaliaText: (event: FormEvent<HTMLTextAreaElement>) => void;
}

function LidInfo(props: ILidInfoProps) {
  const { list, setRegalias, regalias, regaliaText, regaliaTextError, setRegaliaText, regaliasError } = props;

  const [isOpen, setOpen] = useState<boolean>(true);

  const handleChangeYear = (value: string, index: number) => {
    list[index].yearGot = +value;
    setRegalias([...list]);
  }

  const handleChangeRegalia = useCallback((value: TOption, index: number) => {
    list[index].scienceRegalia = value.value;
    setRegalias([...list]);
  }, [list, setRegalias]);

  const addRegalia = () => {
    setRegalias([
      ...list,
      { yearGot: 0, scienceRegalia: '' },
    ]);
  };

  const removeRegalia = (regalia: TPersonRegalia) => {
    const newRegalias = list.filter(item => item !== regalia);

    setRegalias(newRegalias);
  };

  return (
    <section className={styles.container}>
      <div className={styles['title-container']} onClick={() => setOpen(isOpen => !isOpen)}>
        <h2 className={styles.title}>Лид-информация</h2>
        <button className={`${styles['hide-button']} ${!isOpen && styles['reverse-button']}`}><Icon type="caret" /></button>
      </div>
      <div className={`${styles['form-container']} ${!isOpen && styles['inputs-hide']}`}>
        <div>
          {
            list.map(
              (item, i) =>
                <div className={styles['regalias-list']} key={i}>
                  <Input
                    name="yaer"
                    label="Год"
                    value={`${item.yearGot}`}
                    handleChange={(event) => handleChangeYear(event.currentTarget.value, i)}
                    type="number"
                  />
                  <Select
                    title="Специальность"
                    placeholder="Выберите специальность"
                    data={regalias.map(item => ({ title: item.name, value: item._id }))}
                    onChange={(value) => handleChangeRegalia(value, i)}
                  />
                  <button className={styles['remove-regalia-button']} onClick={() => removeRegalia(item)}>
                    <Icon type="trash" />
                  </button>
                </div>
            )
          }
          <button
            className={styles['add-button']}
            onClick={addRegalia}
          >
            Добавить пункт
          </button>
          <div className={styles['error-message']}>
            {regaliasError}
          </div>
        </div>
        <TextArea
          label="Дополнительный текст"
          name="personScienceRegaliaAdvText"
          placeholder="Добавьте текст"
          value={regaliaText}
          error={regaliaTextError}
          handleChange={setRegaliaText}
          inputClassName={styles.textarea}
        />
      </div>
    </section>
  );
}

export default memo(LidInfo);
