import { useCallback, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth, useForm } from '../../Services/hooks';
import { useAppDispatch, useAppSelector } from '../../Services/hooks/useStore';
import { regaliasSelector } from '../../Services/slices/regalias';
import { systemSelector } from '../../Services/slices/system';
import { personsSelector, changeStatus } from '../../Services/slices/persons';
import { TPersonForCreate, TPersonRegalia, TGalleryData } from '../../types/person';
import fetchRegaliasList from '../../Services/thunks/fetchRefaliasList';
import createPerson from '../../Services/thunks/createPerson';

import { Button, Layout, Paper, Loader, Modal } from '../../Components';
import { Photo, MainInfo, LidInfo, MainText, Literature, Gallery } from './Components';
import styles from './PersonAdd.module.css';

function PersonAdd() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { list: regaliasList, loading: regaliasLoading } = useAppSelector(regaliasSelector);
  const { status, loading } = useAppSelector(personsSelector);
  const { redirect } = useAppSelector(systemSelector);
  const mainInfoFormData = useForm({
    lastName: '',
    firstName: '',
    middleName: '',
    birthday: '',
    deathday: '',
    description: '',
  }, {
    lastNameError: '',
    firstNameError: '',
    middleNameError: '',
    birthdayError: '',
    deathdayError: '',
    descriptionError: '',
  });

  // Main literature
  const [mainLiteratureList, setMainLiteratureList] = useState<string[]>([]);

  // About literature
  const [aboutLiteratureList, setAboutLiteratureList] = useState<string[]>([]);

  // Additionally literature
  const [additionallyLiteratureList, setAdditionallyLiteratureList] = useState<string[]>([]);

  // Gallery
  const [gallery, setGallery] = useState<TGalleryData[]>([]);

  // Photo
  const [photo, setPhoto] = useState<File | null>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);

  const upload = () => {
    const reader  = new FileReader();

    if(avatarInputRef.current && avatarInputRef.current.files) {
      const file = avatarInputRef.current.files['0'];

      reader.onloadend = () => {
        if (avatarRef.current && typeof reader.result === 'string') {
          
          avatarRef.current.src = reader.result;
        }
      };

      if (file) {
        console.log(file);
        
        reader.readAsDataURL(file);
        setPhoto(file);
      } else {
        setPhoto(null);
      }
    }
  }

  // Lid Info
  const regaliaText = useForm({
    personScienceRegaliaAdvText: '',
  }, {
    personScienceRegaliaAdvTextError: '',
  });
  const [regalias, setRegalias] = useState<TPersonRegalia[]>([]);
  const [regaliaError, setRegaliaError] = useState<string>('');

  // Main Text
  const [mainText, setMainText] = useState<string>('');

  // Loading Regalias
  useEffect(() => {
    dispatch(fetchRegaliasList());
  }, [dispatch]);

  const savePerson = useCallback(() => {
    let errors: any = {
      lastNameError: '',
      firstNameError: '',
      middleNameError: '',
      descriptionError: '',
    };
    const { values: mainValues, setErrors: setMainErrors } = mainInfoFormData;

    // Проверка основной информации
    if (!mainValues.lastName) {
      errors.lastNameError = 'Необходимо заполнить все обязательные поля';
    }
    if (!mainValues.firstName) {
      errors.firstNameError = 'Необходимо заполнить все обязательные поля';
    }
    if (!mainValues.middleName) {
      errors.middleNameError = 'Необходимо заполнить все обязательные поля';
    }
    if (!mainValues.description) {
      errors.descriptionError = 'Необходимо заполнить все обязательные поля';
    }
    if (errors.lastNameError || errors.firstNameError || errors.middleNameError || errors.personScienceRegaliaAdvTextError) {
      return setMainErrors(errors);
    }

    // Проверка Лид-информации
    errors = {
      regaliaTextError: '',
      regaliasError: '',
    };
    regalias.forEach(item => {
      if (!item.scienceRegalia || !item.yearGot) {
        errors.regaliasError = 'Необходимо заполнить все поля';
      }
    });
    if (errors.regaliasError) {
      return setRegaliaError('Необходимо заполнить все поля');
    }
    if (errors.regaliaTextError) {
      return regaliaText.setErrors({ personScienceRegaliaAdvTextError: 'Необходимо заполнить все поля' });
    }

    // Проверка основного текста
    /* if (!mainText.values.mainText) {
      return mainText.setErrors({ mainTextError: 'Необходимо заполнить это поле' });
    } */

    const person: TPersonForCreate = {
      name: {
        first: mainInfoFormData.values.firstName,
        last: mainInfoFormData.values.lastName,
        middle: mainInfoFormData.values.middleName,
      },
      imgHref: '',
      birthday: mainInfoFormData.values.birthday,
      deathday: mainInfoFormData.values.deathday,
      description: mainInfoFormData.values.description,
      personScienceRegaliaAdvText: regaliaText.values.personScienceRegaliaAdvText,
      personScienceRegalia: regalias,
      mainText: mainText,
      literatureOfPerson: mainLiteratureList,
      literatureAboutPerson: aboutLiteratureList,
      personAdvInfo: additionallyLiteratureList,
      gallery: [],
    };

    dispatch(createPerson(person, photo, gallery));
  }, [
    mainInfoFormData, regalias, regaliaText, mainText,
    dispatch, photo, mainLiteratureList, aboutLiteratureList,
    additionallyLiteratureList, gallery,
  ]);

  const completed = useCallback(() => {
    dispatch(changeStatus({ field: 'isSuccesCreate', value: false }));
  }, [dispatch]);

  return (
    <Layout
      title="Добавление персоны"
      user={user}
      headerContent={<div className={
        styles['header-content']}>
          <Button
            color="primary"
            size="large"
            className={styles['header-button']}
            onClick={savePerson}
          >
            Добавить персону
          </Button>
          <Link to="/">
            <Button color="red" size="large">Отменить</Button>
          </Link>
        </div>
      }
    >
      {
        (regaliasLoading && !regaliasList) ?
          <section className={styles['loader-container']}>
            <Loader className={styles.loader} />
          </section> : <></>
      }
      {
        regaliasList ?
        <Paper className={styles.grid}>
          <Photo
            hasPhoto={!!photo}
            setPhoto={setPhoto}
            inputRef={avatarInputRef}
            imgRef={avatarRef}
          />
          <input
            type="file"
            className={styles['file-upload-input']}
            ref={avatarInputRef}
            onChange={upload}
            id="photo-upload"
          />
          <div className={styles['form-container']}>
            <MainInfo formData={mainInfoFormData} />
            <LidInfo
              list={regalias}
              setRegalias={setRegalias}
              regalias={regaliasList}
              regaliaText={regaliaText.values.personScienceRegaliaAdvText}
              regaliaTextError={regaliaText.errors.personScienceRegaliaAdvTextError}
              setRegaliaText={regaliaText.handleChange}
              regaliasError={regaliaError}
            />
            <MainText
              value={mainText}
              handleChange={setMainText}
              //error={mainText.errors.mainTextError}
            />
            <Literature
              title="Избранные труды"
              literatureList={mainLiteratureList}
              setLiteratureList={setMainLiteratureList}
              literatureType="mainLiterature"
            />
            <Literature
              title="Литература о жизни и деятельности"
              literatureList={aboutLiteratureList}
              setLiteratureList={setAboutLiteratureList}
              literatureType="aboutLiterature"
            />
            <Literature
              title="Дополнительные материалы"
              literatureList={additionallyLiteratureList}
              setLiteratureList={setAdditionallyLiteratureList}
              literatureType="additionalyLiterature"
            />
            <Gallery
              gallery={gallery}
              setGallery={setGallery}
            />
          </div>
        </Paper> : <></>
      }
      { loading.create || status.isSuccesCreate ?
        <Modal title="Создание персоны" close={() => console.log(123)}>
          <div className={styles['modal-content']}>
            <span className={styles['modal-name']}>
              {mainInfoFormData.values.lastName} {mainInfoFormData.values.firstName} {mainInfoFormData.values.middleName}
            </span>
            {
              loading.create &&
              <>
                <span className={styles['modal-notifiction']}>
                  Создание персоны...
                </span>
                <div className={styles['modal-loader-container']}>
                  <Loader className={styles['modal-loader']} />
                </div>
              </>
            }
            {
              status.isSuccesCreate &&
              <>
                <span className={styles['modal-notifiction']}>
                  Персона успешно добавлена!
                </span>
                <Link to={redirect} className={styles['modal-button-link']}>
                  <Button size="large" className={styles['modal-button']} onClick={completed}>
                    Перейти на страницу персоны
                  </Button>
                </Link>
              </>
            }
          </div>
        </Modal> : <></>
      }
    </Layout>
  );
}

export default PersonAdd;