import { RefObject } from 'react';

import { Icon } from '../../../../Components';
import styles from './Photo.module.css';

interface IPhotoProps {
  hasPhoto: boolean;
  setPhoto: (value: File | null) => void;
  inputRef: RefObject<HTMLInputElement>;
  imgRef: RefObject<HTMLImageElement>;
}

function Photo(props: IPhotoProps) {
  const { hasPhoto, setPhoto, inputRef, imgRef } = props;

  const remove = () => {
    setPhoto(null);
    if (imgRef.current && inputRef.current && inputRef.current.files) {
      imgRef.current.src = '/images/user.png';
      inputRef.current.value = '';
    }
  }

  return (
    <div className={styles.container}>
      <img
        src='/images/user.png'
        alt="user"
        className={hasPhoto ? styles.photo : styles['no-photo']}
        ref={imgRef}
      />
      <label
        htmlFor="photo-upload"
        className={`${styles.button} ${styles.add}`}
      >
        <Icon type="add-blue" />
      </label>
      <button className={`${styles.button} ${styles.cancel}`} onClick={remove}>
        <Icon type="cancel" />
      </button>
    </div>
  );
}

export default Photo;
