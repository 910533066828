import { configureStore } from "@reduxjs/toolkit";
import authReducer from '../Services/slices/auth';
import personsReducer from '../Services/slices/persons';
import literatureReducer from '../Services/slices/literatures';
import regaliasReducer from '../Services/slices/regalias';
import userReducer from '../Services/slices/users';
import systemReducer from '../Services/slices/system';

const store = configureStore({
  reducer: {
    auth: authReducer,
    persons: personsReducer,
    literature: literatureReducer,
    regalias: regaliasReducer,
    user: userReducer,
    system: systemReducer,
  },
});


export type TRootStore = ReturnType<typeof store.getState>;

export type TAppDispatch = typeof store.dispatch;

export default store;
