import { useAuth, useForm } from '../../Services/hooks';
import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../Services/hooks/useStore';
import { userSelector, changeStatus, clearUser } from '../../Services/slices/users'
import { useParams, Link, useLocation,  } from 'react-router-dom';
import { Layout, Paper, Loader, Button, Modal } from '../../Components';
import { TUserEdit } from '../../types/user';
import EmailValidator from 'email-validator';
import editUser from '../../Services/thunks/editUser';
import styles from './UserEdit.module.css';

import MainInfo from './Components/MainInfo';
import fetchUser from '../../Services/thunks/fetchUser';

function UserEdit() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { status, loading, errors, userData } = useAppSelector(userSelector);
  const [modalData, setModalData] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const { username } = useParams();
  // Загрузка персоны
  useEffect(() => {
    if (username) {
      if (userData?.username !== username) {
        dispatch(fetchUser(username));
      }
    }
  }, [username, dispatch, userData, loading.user]);

  const mainInfoFormData = useForm({
    firstName: '',
    middleName: '',
    lastName: '',
    role: '',
    password: '',
  }, {
    firstNameError: '',
    middleNameError: '',
    lastNameError: '',
    role:'',
    passwordError: '',
  });

  const setValues = ()=>{
    mainInfoFormData.values.firstName = userData?.name?.first || ''
    mainInfoFormData.values.middleName = userData?.name?.middle || ''
    mainInfoFormData.values.lastName = userData?.name?.last || ''
    mainInfoFormData.values.role = role || ''
  }
  // setValues()

  useEffect(() => {
    setValues()
  }, [userData, role]);
  
  const saveUser = useCallback(()=>{
    const keys = ['firstName','middleName','lastName']
    let errors:any = {};
    for(let key of keys){ errors[key] = '' }
    const { values: mainValues, setErrors: setMainErrors } = mainInfoFormData;

    let isErrors = false
    for(let key of keys){
      if (!mainValues[key]){ 
        errors[key+'Error']= 'Необходимо заполнить все обязательные поля';
        isErrors = true;
      }
    }
    if (role === null) {
      errors.roleError = 'Выберите роль';
    }

    if ((mainValues.password) && (mainValues.password.length < 6 || mainValues.password.length > 16)) {
      errors.passwordError = 'Пароль должен содержать от 6 до 16 символов';
      isErrors = true;
    }


    if (isErrors){ return setMainErrors(errors) }

    const user: TUserEdit = {
      name:{
        first: mainInfoFormData.values.firstName,
        middle: mainInfoFormData.values.middleName,
        last: mainInfoFormData.values.lastName,
      },
      role: role || 'admin',
      ...(mainValues.password && { password: mainValues.password }),
    }
    

    dispatch(editUser(username || '', user ))

  }, [mainInfoFormData, dispatch, role, username])


  const completed = useCallback(() => {
    dispatch(changeStatus({ field: 'isSuccessEdit', value: false }));
    dispatch(clearUser())
  }, [dispatch]);

  const location = useLocation();
  const locationPage = location.state?.page || null;

  useEffect(() => {
    return () => {
      dispatch(clearUser());
    };
  }, [dispatch]);

  return (
    <Layout
      title="Редактирование персоны"
      user={user}
      headerContent={
        <div className={styles['header-content']}>
          <Button
            color="primary"
            size="large"
            className={styles['header-button']}
            onClick={saveUser}
          >
            Сохранить изменения
          </Button>
          <Link to="/user" state={{ page: locationPage }}>
            <Button color="red" size="large">Отменить</Button>
          </Link>
        </div>
    }
    >
      {
        loading.user ?
        <Loader className={styles.loader} /> : <></>
      }
      {
        errors.user ?
        <p className={styles['error-message']}>{errors.user}</p> : <></>
      }
      {
        userData ? 
        <MainInfo
          mainInfoFormData={mainInfoFormData}
          role={role}
          setRole={setRole}
          userRole={user?.role || ''}
        /> : <></>
      }
      {
        loading.edit || status.isSuccessEdit ? <Modal title="Редактирование персоны" close={() => console.log(123)}>
        <div className={styles['modal-content']}>
          <span className={styles['modal-name']}>
            {mainInfoFormData.values.lastName} {mainInfoFormData.values.firstName} {mainInfoFormData.values.middleName}
          </span>
          {
            loading.edit &&
            <>
              <span className={styles['modal-notifiction']}>
                Сохранение изменений...
              </span>
              <div className={styles['modal-loader-container']}>
                <Loader className={styles['modal-loader']} />
              </div>
            </>
          }
          {
            status.isSuccessEdit &&
            <>
              <span className={styles['modal-notifiction']}>
                Пользователь успешно изменён!
              </span>
              <Link to="/user" className={styles['modal-button-link']} state={{ page: locationPage }}>
                <Button size="large" className={styles['modal-button']} onClick={completed}>
                  Продолжить
                </Button>
              </Link>
            </>
          }
        </div>
      </Modal> : <></>
      }
    </Layout>
  )
}

export default UserEdit;