import { IUseFormResult } from '../../../Services/hooks/useForm';

import styles from './MainInfo.module.css'
import { Input, Paper, Select } from '../../../Components';

const roles = [
  {title: 'Администратор', value: 'admin'},
  {title: 'Главный администратор', value: 'superAdmin'},
];

interface IMainInfoProps {
  mainInfoFormData: IUseFormResult,
  role: null | string;
  setRole: (value: string) => void;
  userRole: string;
}

function MainInfo(props:IMainInfoProps){

  const { mainInfoFormData, role, setRole, userRole } = props
  const { values, errors, handleChange } = mainInfoFormData;
  return(
    <div className={styles.paper_container}>
      <Paper className={styles.paper}>
        <>
        <h2 className={styles.paper_header_title}>
          ФИО
        </h2>
        <div className={styles.row}>
          <Input
            label="Фамилия"
            name="middleName"
            value={values.middleName}
            handleChange={handleChange}
            className={styles.middleName}
            placeholder="Введите фамилию"
            error={errors.middleNameError}
            required
          />
        </div>
        <div className={styles.row}>
          <Input
            label="Имя"
            name="firstName"
            value={values.firstName}
            handleChange={handleChange}
            className={styles.firstName}
            placeholder="Введите имя"
            error={errors.firstNameError}
            required
          />
        </div>
        <div className={styles.row}>
          <Input
            label="Отчество"
            name="lastName"
            value={values.lastName}
            handleChange={handleChange}
            className={styles.lastName}
            placeholder="Введите отчество"
            error={errors.lastNameError}
            required
          />
        </div>
        </>
      </Paper>
      <Paper className={styles.paper}>
        <>
        <h2 className={styles.paper_header_title}>
          Данные для авторизации
        </h2>
        <div className={styles.row}>
          <Select
            title="Роль"
            placeholder="Выберите роль"
            data={roles}
            onChange={(role) => setRole(role.value)}
          />
        </div>
        {
          userRole === 'superAdmin' &&
          <div className={styles.row}>
            <Input
              label="Пароль"
              name="password"
              value={values.password}
              handleChange={handleChange}
              className={styles.lastName}
              placeholder="Введите новый пароль"
              error={errors.passwordError}
            />
          </div>
        }
        </>
      </Paper>
    </div>
  )
}

export default MainInfo