import { useState, memo, useCallback, useRef, useEffect, FormEvent } from 'react';
import { Icon, Modal, Input, Button, Loader } from '../../../../Components';
import { useForm } from '../../../../Services/hooks';
import { useAppDispatch, useAppSelector } from '../../../../Services/hooks/useStore';
import createLiteratureAndUpdateThunk from '../../../../Services/thunks/createLiteratureAndUpdate';
import fetchLiteratureListThunk from '../../../../Services/thunks/fetchLiteratureList';
import { literatureSelector, changeStatus } from '../../../../Services/slices/literatures';
import { TLiterature, TLiteratureCreater } from '../../../../types/literature';

import styles from './Literature.module.css';

interface IMainLiterature {
  literatureList: string[];
  setLiteratureList: (value: string[]) => void;
  title: string;
  literatureType: 'mainLiterature' | 'aboutLiterature' | 'additionalyLiterature';
}

function MainLiterature(props: IMainLiterature) {
  const { literatureList, setLiteratureList, title: componentTitle, literatureType } = props;
  const dispatch = useAppDispatch();
  const { loading, status, list, errors: literatureErrors } = useAppSelector(literatureSelector);
  const [isOpen, setOpen] = useState(true);
  const [isOpenModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [renderList, setRenderList] = useState<TLiterature[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [mode, setMode] = useState<'create' | 'select'>('select');
  const { values, errors, handleChange, setErrors, resetForm } = useForm({
    title: '',
    link: '',
    search: '',
  }, {
    titleError: '',
  });
  const inputFileRef = useRef<HTMLInputElement>(null);

  // Создание литературы
  useEffect(() => {
    if (status.isSuccesCreate) {
      cancel();
    }
  }, [status.isSuccesCreate]); // eslint-disable-line

  // Загрузка списка литературы
  useEffect(() => {
    dispatch(fetchLiteratureListThunk(0, 999));
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      if (search === "" ) {
        setRenderList(list);
      } else {
        setRenderList(list.filter(item => item.title.indexOf(search) > -1));
      }
    }
  }, [list, search]);

  // Created literature handler
  useEffect(() => {
    if (status[literatureType]) {
      setLiteratureList([...literatureList, status[literatureType]]);
      dispatch(changeStatus({ name: literatureType, value: '' }));
    }
  }, [literatureType, status, dispatch, literatureList, setLiteratureList]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const cancel = useCallback(() => {
    resetForm();
    setOpenModal(false);
    setFile(null);
  }, [resetForm]);

  const addFile = () => {
    if (inputFileRef.current && inputFileRef.current.files) {
      const file = inputFileRef.current.files[0];

      if (file) {
        setFile(file);
      }
    }
  };

  const createLiterature = () => {
    if (!values.title) {
      return setErrors({
        ...errors,
        titleError: 'Укажите название работы',
      });
    }

    const literature: TLiteratureCreater = {
      title: values.title,
      author: [],
      yearOut: 0,
      pageNumber: 0,
      fullTextLink: values.link,
    };

    dispatch(createLiteratureAndUpdateThunk(literature, file, literatureType));
  };

  const addLiteratureToList = (literature: string) => {
    setLiteratureList([...literatureList, literature]);
  };

  const removeLiteratureFromList = (literature: string) => {
    setLiteratureList(literatureList.filter(item => item !== literature));
  };

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setSearch(value);
  };

  return (
    <section className={styles.container}>
      <div className={styles['header-wrapper']}>
        <div className={styles['title-container']} onClick={() => setOpen(isOpen => !isOpen)}>
          <h2 className={styles.title}>{componentTitle}</h2>
          <button
            className={`${styles['hide-button']} ${!isOpen && styles['reverse-button']}`}
          >
            <Icon type="caret" />
          </button>
        </div>
        <button
          className={styles['add-button']}
          onClick={() => setOpenModal(true)}
        >
          Добавить пункт
        </button>
      </div>
      <div className={`${styles.list} ${!isOpen && styles['inputs-hide']}`}>
        {
          literatureList.length === 0 ?
            <p>Список пуст</p> :
            literatureList.map(
              item => {
                if (list) {
                  const literature = list.find(literature => literature._id === item);

                  if (literature) {
                    return (
                      <div key={item} className={styles['main-list-item']}>
                        <span>{literature.title}</span>
                        <button onClick={() => removeLiteratureFromList(item)}><Icon type="trash" /></button>
                      </div>
                    )
                  }
                }

                return null;
              }
            )
        }
      </div>
      {
        isOpenModal &&
        <Modal
          close={closeModal}
          title="Избранные труды"
          modalClassName={styles.modal}
        >
          <div className={styles['select-mode']}>
            <span
              className={`${styles['select-button']} ${mode === 'create' && styles['select-button-active']}`}
              onClick={() => setMode('create')}
            >
              Добавить вручную
            </span>
            <span
              className={`${styles['select-button']} ${mode === 'select' && styles['select-button-active']}`}
              onClick={() => setMode('select')}
            >
              Выбрать из списка
            </span>
          </div>
          {
            mode === 'create' ?
              <div className={styles['modal-content']}>
                <Input
                  name="title"
                  value={values.title}
                  handleChange={handleChange}
                  label="Название"
                  placeholder="Название работы"
                  required
                  error={errors.titleError}
                />
                <label className={`${styles['file-input-label']} ${styles.input}`}>
                  <span className={styles.label}>
                    Файл с полным текстом (pdf)
                  </span>
                    <div
                      className={styles['file-input-container']}
                    >
                      <input
                        className={styles['file-input']}
                        type="file"
                        name="file"
                        onChange={addFile}
                        ref={inputFileRef}
                        accept='.pdf'
                      />
                      {
                        file === null ?
                          <span className={styles.placeholder}>Файл не выбран</span>
                          : <span className={styles.placeholder}>{file.name}</span>
                      }
                  </div>
                </label>
                <Input
                  name="link"
                  value={values.link}
                  handleChange={handleChange}
                  label="Ссылка на полный текст"
                  placeholder="Укажите ссылку на полный текст"
                  className={styles.input}
                />
                <div className={styles.buttons}>
                  <Button
                    onClick={createLiterature}
                    size="large"
                    color="primary"
                    disabled={loading.create}
                  >
                    Добавить
                  </Button>
                  <Button
                    onClick={cancel}
                    size="large"
                    color="black"
                    disabled={loading.create}
                  >
                    Отменить
                  </Button>
                </div>
              </div> : <></>
          }
          {
            mode === 'select' ?
            <div className={styles['modal-content']}>
              {
                literatureErrors.list &&
                <span className={styles['modal-error']}>
                  {literatureErrors.list}
                </span>
              }
              {
                (list === null && loading.create) &&
                <div className={styles['loader-container']}>
                  <Loader className={styles.loader} />
                </div>
              }
              {
                list &&
                <div>
                  <div className={styles['literature-list-container']}>
                    <h3 className={styles['literature-list-title']}>Список литературы</h3>
                    <div className={styles['literature-search-container']}>
                      <Input
                        value={search}
                        handleChange={handleSearch}
                        name="search"
                        label="Поиск"
                        placeholder="Поиск по названию"
                      />
                    </div>
                    <div className={styles['literature-list']}>
                      {
                        renderList.length ?
                          renderList
                            .map(
                              (item, i) =>
                                <div key={i} className={styles['literature-list-item']}>
                                  {
                                    literatureList.find(literature => literature === item._id) ?
                                      <Icon
                                        type="checkbox-on"
                                        onClick={() => removeLiteratureFromList(item._id)}
                                      /> :
                                      <Icon
                                        type="checkbox-off"
                                        onClick={() => addLiteratureToList(item._id)}
                                      />
                                  }
                                  <span className={styles['literature-title']}>{item.title}</span>
                                </div>
                            ) :
                          <p className={styles['literature-empty-list-p']}>По вашему запросу ничего не найдено</p>
                      }
                    </div>
                  </div>
                  
                  <div className={styles.buttons}>
                    <Button
                      onClick={cancel}
                      size="large"
                      color="primary"
                      disabled={loading.create}
                    >
                      Добавить
                    </Button>
                  </div>
                </div>
              }
            </div> : <></>
          }
        </Modal>
      }
    </section>
  );
}

export default memo(MainLiterature);
