import axios from '../axios';
import { TAxiosResult } from '../types/axios';
import { TLiterature, TFetchLiteratureListParams, TLiteratureListSizeResult, TLiteratureRemoveResult,
  TLiteratureCreater
} from '../types/literature'

class LiteratureApi {
  static async fetchList( params?: TFetchLiteratureListParams): Promise<TAxiosResult<TLiterature[]>>{
    return await axios.get('/api/literature/list', {
      params
    })
      .catch(({ response }) => ({...response}))
  }

  static async fetchListSize(params?: TFetchLiteratureListParams): Promise<TAxiosResult<TLiteratureListSizeResult>> {
    return await axios.get('/api/literature/listSize', {
      params,
    })
      .catch(({ response }) => ({ ...response }));
  };

  static async removeLiterature(_id: string, token: string): Promise<TAxiosResult<TLiteratureRemoveResult>> {
    return await axios.delete(`/api/literature/delete/item/${_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async createLiterature(literature: TLiteratureCreater, token: string): Promise<TAxiosResult<TLiterature>> {
    return await axios.post('/api/literature', literature, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({ ...response }));
  }

  static async uploadPdfVersion(_id: string, img: any, token: string): Promise<TAxiosResult<any>> {
    const data = new FormData();
    data.append('pdf_version', img);

    return await axios.put(`/api/literature/upload/${_id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({ ...response }));
  }

  static async editLiterature(id: string, token: string, title: string, fullTextLink: string, author: string[]): Promise<TAxiosResult<any>> {
    return await axios.put(`/api/literature/${id}`, {
      title, fullTextLink, author,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async removePdf(_id: string, token: string): Promise<TAxiosResult<any>> {
    return await axios.delete(`/api/literature/delete/pdf/${_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({ ...response }));
  }
}

export default LiteratureApi