import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootStore } from "../../store";
import { TUser, TUserListSizeResult } from "../../types/user";

interface IUserState {
  list: TUser[] | null;
  userData: TUser | null;
  size: number | null;
  loading: {
    list: boolean;
    size: boolean;
    remove: boolean;
    create: boolean;
    user: boolean;
    edit: boolean;
  };
  status:{
    isSuccesCreate: boolean;
    isSuccessEdit: boolean;
  };
  errors: {
    list: string;
    size: string;
    remove: string;
    create: string;
    user: string;
    edit: string;
  };
}

const initialState: IUserState = {
  list: null,
  size: null,
  userData: null,
  loading: {
    list: false,
    size: false,
    remove: false,
    create: false,
    user:false,
    edit:false,
  },
  status:{
    isSuccesCreate: false,
    isSuccessEdit: false,
  },
  errors: {
    list: '',
    size: '',
    remove: '',
    create: '',
    user:'',
    edit:'',
  }
}

export const userSlice = createSlice({
  name:"user",
  initialState,
  reducers:{
    fetchList: state => {
      state.loading = {
        ...state.loading,
        list: true,
      };
      state.errors = {
        ...state.errors,
        list: '',
      };
    },
    fetchUser: (state) => {
      state.loading = {
        ...state.loading,
        user: true,
      };
      state.errors = {
        ...state.errors,
        user: '',
      };
    },
    fetchUserSuccess: (state, action: any) => {
      state.loading = {
        ...state.loading,
        user: false,
      };
      state.userData = action.payload;
    },
    fetchUserFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        user: action.payload,
      };
      state.loading = {
        ...state.loading,
        user: false,
      };
    },
    fetchListSuccess: (state, action: PayloadAction<TUser[]>) => {
      state.loading = {
        ...state.loading,
        list: false,
      };
      state.list = action.payload;
    },
    fetchListFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        list: action.payload,
      };
      state.loading = {
        ...state.loading,
        list: false,
      };
    },
    fetchListSize: (state) => {
      state.loading = {
        ...state.loading,
        size: true,
      };
      state.errors = {
        ...state.errors,
        size: '',
      };
    },
    fetchListSizeSuccess: (state, action: PayloadAction<TUserListSizeResult>) => {
      state.loading = {
        ...state.loading,
        size: false,
      };
      state.size = action.payload[0].n;
    },
    fetchListSizeFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        size: action.payload,
      };
      state.loading = {
        ...state.loading,
        size: false,
      };
    },
    remove: (state) => {
      state.loading = {
        ...state.loading,
        remove: true,
      };
      state.errors = {
        ...state.errors,
        remove: '',
      };
    },
    removeSuccess: (state) => {
      state.loading = {
        ...state.loading,
        remove: false,
      };
    },
    removeFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        remove: action.payload,
      };
      state.loading = {
        ...state.loading,
        remove: false,
      };
    },

    create: (state) => {
      state.loading = {
        ...state.loading,
        create: true,
      };
      state.status = {
        ...state.status,
        isSuccesCreate:false,
      };
      state.errors = {
        ...state.errors,
        create: '',
      };
    },
    createSuccess: (state) => {
      state.loading = {
        ...state.loading,
        create: true,
      };
      state.status = {
        ...state.status,
        isSuccesCreate:true,
      };
    },
    createEnd: (state) => {
      state.loading = {
        ...state.loading,
        create: false,
      };
      state.status = {
        ...state.status,
        isSuccesCreate:false,
      };
    },
    createFail: (state, action: PayloadAction<string>) => {
      state.loading = {
        ...state.loading,
        create: false,
      };
      state.errors = {
        ...state.errors,
        create: action.payload,
      };
    },
    editUser: (state) => {
      state.loading = {
        ...state.loading,
        user: true,
      };
      state.errors = {
        ...state.errors,
        user: '',
      };
    },
    editUserSuccess: (state) => {
      state.loading = {
        ...state.loading,
        edit: false,
      };
      state.status = {
        ...state.status,
        isSuccessEdit: true,
      };
    },
    editUserFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        edit: action.payload,
      };
      state.loading = {
        ...state.loading,
        edit: false,
      };
    },
    changeStatus: (state, action: PayloadAction<{field: string; value: boolean}>) => {
      state.status = {
        ...state.status,
        [action.payload.field]: action.payload.value,
      };
    },
    clearUser: (state) =>{
      state.userData = null
    },
  }
})

export const {
  fetchUser,
  fetchUserSuccess,
  fetchUserFail,
  fetchList,
  fetchListSuccess,
  fetchListFail,
  fetchListSize,
  fetchListSizeSuccess,
  fetchListSizeFail,
  remove,
  removeSuccess,
  removeFail,
  create,
  createSuccess,
  createFail,
  createEnd,
  editUser,
  editUserSuccess,
  editUserFail,
  changeStatus, 
  clearUser,
} = userSlice.actions;

export const userSelector = (state: TRootStore) => state.user;

export default userSlice.reducer;