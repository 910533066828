import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import LiteratureApi from '../../API/LiteratureApi';

function removeLiteratureThunk(_id: string): ThunkAction<void, TRootStore, unknown, AnyAction>{
  return async dispatch => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await LiteratureApi.removePdf(_id, token);
  
        if (result.status === 200) {
          console.log('PDF Удален')
        }
      } catch(error) {
      }
    }
  }
}

export default removeLiteratureThunk;