import { IUseFormResult } from '../../../../Services/hooks/useForm';
import { useState } from 'react';

import styles from './MainInfo.module.css'
import { Button, Input, Paper } from '../../../../Components';

interface IMainInfoProps {
  formData:{
    mainInfoFormData: IUseFormResult,
    authors: string[],
    pdfVersionPath: any,
  },
  setPdfVersion: Function,
  setAuthors: Function,
  errors:{
    pdfVersionError:string,
  }
}

function MainInfo(props:IMainInfoProps){

  const { mainInfoFormData, authors  } = props.formData
  const { setPdfVersion, setAuthors } = props
  const { values, errors, handleChange } = mainInfoFormData;
  const propsError = props.errors
  const [ pdfName, setPdfName ] = useState<string>('Название файла')

  const handleAuthor = ( val:any, ind:number) => {
    authors[ind] = val.value
    setAuthors([...authors])
  }

  const addAuthor = ()=>{
    setAuthors([...authors, ''])
  }

  const handlePdfVersion = ( event:any ) => {
    const file = event.target.files[0]
    setPdfName(file.name)
    setPdfVersion(file)
  }

  return(
    <div className={styles.paper_container}>
      <Paper className={styles.paper}>
        <>
        <h2 className={styles.paper_header_title}>
          Документ
        </h2>
        <div className={styles.row}>
          <Input
            // className={styles.lastname}
            label='Название'
            name='title'
            value={values.title}
            handleChange={handleChange}
            placeholder='Введине название'
            error={errors.titleError}
            required
          />
        </div>
        <div className={styles.row}>

          <label  className={styles.label_pdf} >
            <div className={styles.label_pdf_title}>
              Файл с полным текстом
            </div>
            <div 
              className={`
                ${styles.label_pdf_container} 
                ${propsError.pdfVersionError ? styles.label_pdf_container_error:''}
              `}
            >
              {pdfName}
            </div>
            <input
              className={styles.input_pdf}
              type='file'
              onChange={handlePdfVersion}
              accept='.pdf'
            />
            { propsError.pdfVersionError && 
              <div className={styles.label_error}>
                {propsError.pdfVersionError}
              </div>
            }
          </label>
          <Input
            label='Ссылка на полный текст'
            name='fullTextLink'
            value={values.fullTextLink}
            handleChange={handleChange}
            placeholder='Введите ссылку'
            error={errors.fullTextLinkError}
          />
        </div>
        </>
      </Paper>
      <Paper className={styles.paper}>
        {/* <div className={styles.row__header}>
          <h2 className={styles.paper_header_title}>
            Авторы
          </h2>
          <button  className={styles.add_btn} onClick={addAuthor}>Добавить автора</button>
        </div>
        <div className={styles.row_wrap}>
          { authors.map( (elem, index) => 
            <Input
              className={styles.input_author}
              key={index + 'author'}
              name={'author'+index}
              value={elem}
              handleChange={ event => { handleAuthor(event.target, index) } }
              placeholder='Добавьте автора'
              error={errors.fullTextLinkError}
            />
          )}
        </div> */}
      </Paper>
    </div>
  )
}

export default MainInfo