import axios from '../axios';
import { TAxiosResult } from '../types/axios';
import { TAuthResult, TAuthProfile } from '../types/auth';

class AuthApi {
  static async fetchLogin(email: string, password: string): Promise<TAxiosResult<TAuthResult>> {
    return await axios.post('/api/auth/login', {
      username: email,
      password,
    })
      .catch(({ response }) => ({ ...response }));
  }

  static async fetchUserData(token: string): Promise<TAxiosResult<TAuthProfile>> {
    return await axios.get('/api/auth/profile', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .catch(({ response }) => ({ ...response }));
  }
}

export default AuthApi;
