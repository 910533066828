import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import UserApi from '../../API/UserApi';
import { remove, removeSuccess, removeFail } from '../slices/users';
import fetchUserList from './fetchUserList';

function removeUserThunk(username:string, skip: number, limit:number, title?: string): ThunkAction<void, TRootStore, unknown, AnyAction>{
  return async dispatch => {
    dispatch(remove());
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await UserApi.removeUser(username, token);
  
        if (result.status === 200) {
          dispatch(removeSuccess());
          dispatch(fetchUserList(skip, limit, title));
        }
      } catch(error) {
        dispatch(removeFail('Ошибка удаления литературы'));
      }
    }
  }
}

export default removeUserThunk;