import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import PersonsApi from '../../API/PersonsApi';
import { editPerson, editPersonSuccess, editPersonFail } from '../slices/persons';
import { TPersonForCreate } from '../../types/person';

function editPersonThunk(_id: string, mainInfo: any, photo: File | string | null): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(editPerson());

    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await PersonsApi.editPerson(_id, token, mainInfo);

        if (result.status === 200) {
          if (photo instanceof File) {
            try {
              await PersonsApi.uploadAvatar(_id, photo, token);
            } catch(error) {
              dispatch(editPersonFail('Ошибка загрузки основной фотографии'));
            }
          }

          dispatch(editPersonSuccess());
        }
      } catch(error) {
        dispatch(editPersonFail('Ошибка редактирования персоны'));
      }
    }
  };
}

export default editPersonThunk;
