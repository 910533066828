import { useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../Services/hooks';
import { useAppDispatch, useAppSelector } from '../../Services/hooks/useStore';
import fetchPersonsList from '../../Services/thunks/fetchPersonsList';
import removePersonThunk from '../../Services/thunks/removePerson';
import { personsSelector } from '../../Services/slices/persons';
import { TPerson } from '../../types/person';

import { Layout, Paper, Loader, Icon, Table, Dropdown, Button, Modal } from '../../Components';
import styles from './PersonsList.module.css';
import getPhotoUrl from '../../utils/getPhotoUrl';

import Alphabet from './Components/Alphabet';

const filterData = [
  'Все персоны',
  'Действительный член',
  'Член-корреспондент',
];

const sortData = [
  'По алфавиту',
  'По дате добавления',
  'По статусу',
];

function PersonsList() {
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { loading, size, errors, list } = useAppSelector(personsSelector);
  const [quantity, setQuantity] = useState<string>(localStorage.getItem('table-quantity') || '10');
  const [page, setPage] = useState<number>(location.state?.page || 0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [sort, setSort] = useState<string>('alphabet')
  const [modalData, setModalData] = useState<TPerson | null>(null);
  const [letter, setLetter] = useState<string>('');


  useEffect(() => {
    dispatch(fetchPersonsList(+quantity * page, +quantity, search, filter, sort, letter));
  }, [dispatch, quantity, page, search, filter, sort, letter]);

  useEffect(() => {
    setPage(0)
  }, [letter, search]);

  const setQuantityCallback = (value: string) => {
    setQuantity(value);
    localStorage.setItem('table-quantity', value);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFilter = useCallback((filter: string) => {
    if (filter === 'Все персоны') {
      return setFilter('');
    }
    setFilter(filter);
  }, []);

  const handleSort = useCallback((sort: string) => {
    if (sort === 'По дате добавления') {
      setSort('date');
    } else if (sort === 'По алфавиту') {
      setSort('alphabet');
    } else if (sort === 'По статусу') {
      setSort('status');
    }
  }, []);

  const closeModal = useCallback(() => {
    setModalData(null);
  }, []);

  const handleRemoveClick = (person: TPerson) => {
    setModalData(person);
  };

  const remove = (_id: string) => {
    dispatch(removePersonThunk(_id, +quantity * page, +quantity, search, filter));
    setModalData(null);
  };

  const fetchListWithLetter = ( newLetter:string ) =>{
    if(letter === newLetter){
      setLetter('')
      return
    }
    setLetter(newLetter)
  }

  return (
    <Layout title="Общий список персон" user={user}>
      <Paper
        title="Общий список персон"
        header
        headerContainer={
          <div  className={styles.sub_header} >
            <div className={styles['header-container']}>
              <div className={styles['search-container']}>
                <input
                  value={search}
                  onChange={(event) => setSearch(event.currentTarget.value)}
                  className={styles['search-input']}
                  placeholder="Поиск персоны"
                />
              </div>
              <Link to="/person/add">
                <Button size="large" className={styles['header-button']}>
                  Добавить
                </Button>
              </Link>
              
            </div>
            <Alphabet
              letter={ letter }
              onClick={ letter => fetchListWithLetter(letter) }
            />
          </div>
        }
      >
        {
          (loading.list || loading.size) ?
          <Loader className={styles.loader} /> : <></>
        }
        {
          (list && size) ?
            <Table
              header={[
                'Фамилия, имя, отчество',
                'Статус',
                'Описание',
                <Dropdown
                  list={filterData}
                  onChange={handleFilter}
                  direction="down"
                />,
                <Dropdown
                  list={sortData}
                  onChange={handleSort}
                  direction="down"
                />,
              ]}
              sizes="4fr 3fr 3fr 3fr 3fr"
              quantity={quantity}
              setQuantity={setQuantityCallback}
              length={size}
              onPageChange={onPageChange}
              page={page}
            >
              {
                list.map(
                  item =>
                  <Table.Row key={item._id} sizes='4fr 3fr 8fr 1fr'>
                    <div className={styles.name}>
                      <img
                        src={item.imgHref ? getPhotoUrl(item._id, item.imgHref) : '/images/no-image.png'}
                        alt={`${item.name.last} ${item.name.first} ${item.name.middle}`}
                      />
                      <span className={styles.text}>
                        {`${item.name.last} ${item.name.first} ${item.name.middle}`}
                      </span>
                    </div>
                    <div className={styles.regalias}>
                      {
                        item.personScienceRegaliaDetail.map(
                          item =>
                          <span key={item.name} className={styles.text}>
                            {item.name}
                          </span>
                        )
                      }
                    </div>
                    <div>
                      {item.personScienceRegaliaAdvText}
                    </div>
                      
                    <div className={styles.buttons}>
                      <Link to={`/person/edit/${item._id}`} state={{ page: page }}>
                        <button>
                          <Icon type="edit" />
                        </button>
                      </Link>
                      <button onClick={() => handleRemoveClick(item)}>
                        <Icon type="trash" />
                      </button>
                    </div>
                  </Table.Row>
                )
              }
            </Table>
            : <></>
        }
        {
          (errors.list || errors.size) ?
          <p className={styles['error-message']}>{errors.list}</p>
          : <></>
        }
      </Paper>
      {
        modalData ?
          <Modal
            close={closeModal}
            title="Удаление персоны"
          >
            <div className={styles['modal-content']}>
              <div className={styles['modal-text']}>
                <p>
                  {modalData.name.last} {modalData.name.first} {modalData.name.middle}
                </p>
                <span>
                  Вы действительно хотите<br/>
                  безвозвратно удалить данную персону?
                </span>
              </div>
              <div className={styles['modal-buttons']}>
                <Button onClick={() => remove(modalData._id)} size="large">Подтвердить</Button>
                <Button color="black" onClick={closeModal} size="large">Отменить</Button>
              </div>
            </div>
          </Modal>
          : <></> }
    </Layout>
  );
}

export default PersonsList;
