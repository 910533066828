import { memo, useState } from 'react';

import Icon from '../Icon/Icon';
import styles from './Select.module.css';

export type TOption = {
  title: string;
  value: string;
};

interface ISelectProps {
  className?: string;
  title: string;
  placeholder: string;
  data: TOption[];
  onChange: (value: TOption) => void;
  value?: string;
}

function Select(props: ISelectProps) {
  const { className, title, data, placeholder, onChange, value } = props;
  
  let defaultValue: TOption | null = null;

  if (value) {
    const option = data.find(item => item.value === value);
    if (option) {
      defaultValue = {
        value: option.value,
        title: option.title,
      };
    }
  }
  

  const [isOpen, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<TOption | null>(defaultValue || null);

  const handleClick = () => {
    setOpen(isOpen => !isOpen);
  }

  const handleSelect = (item: TOption) => {
    setSelected(item);
    onChange(item);
  }

  return (
    <div className={`${styles.container} ${className}`} onClick={handleClick}>
      <span className={styles.title}>{title}</span>
      <div className={styles.field}>
        <span className={`${styles['selected-field']} ${!selected && styles.placeholder}`}>
          {
            selected ? selected.title : placeholder
          }
        </span>
        <button className={`${styles.button} ${isOpen && styles['button-reverse']}`}>
          <Icon type="caret" />
        </button>
      </div>
      {
        isOpen && 
          <div className={styles.menu}>
            {
              data.map(
                item =>
                  <div className={styles['list-item']} key={item.value} onClick={() => handleSelect(item)}>
                    {item.title}
                  </div>
              )
            }
          </div>
      }
    </div>
  );
}

export default memo(Select);
