import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../Services/hooks';

interface IProtectedRouteProps {
  element: JSX.Element;
  anonymous?: boolean;
}

function ProtectedRoute(props: IProtectedRouteProps) {
  const { element, anonymous } = props;
  const { isAuth } = useAuth();
  const location = useLocation();

  const from = location.state?.from || '/';
  // Если разрешен только неавторизованный доступ, а пользователь авторизован...
  if (anonymous && isAuth) {
    // ...то отправляем его на предыдущую страницу
    return <Navigate to={from} />;
  }

  // Если требуется авторизация, а пользователь не авторизован...
  if (!anonymous && !isAuth) {
    // ...то отправляем его на страницу логин
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Если все ок, то рендерим внутреннее содержимое
  return element;
}

export default ProtectedRoute;
