import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Services/hooks';
import { useAppDispatch, useAppSelector } from '../../Services/hooks/useStore';

import fetchLiteratureList from '../../Services/thunks/fetchLiteratureList';
import removeLiteratureThunk from '../../Services/thunks/removeLiterature';
import { literatureSelector } from '../../Services/slices/literatures';
import { TLiterature } from '../../types/literature';




import { Layout, Paper, Loader, Icon, Table, Dropdown, Button, Modal } from '../../Components';
import styles from './LiteratureList.module.css';

const sortData = [
  'По дате добавления',
  'По алфавиту',
];

function LiteratureList() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { loading, size, errors, list } = useAppSelector(literatureSelector);
  const [quantity, setQuantity] = useState<string>(localStorage.getItem('table-quantity-literature') || '10');
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [modalData, setModalData] = useState<TLiterature | null>(null);
  const [sort, setSort] = useState<string>('date');

 

  useEffect(() => {
    dispatch(fetchLiteratureList( +quantity * page, +quantity, search, sort));
  }, [dispatch, quantity, page, search, sort]);

  useEffect(() => {
    setPage(0)
  }, [search]);

  let timerSearch: number | null | NodeJS.Timeout = null
  const setTimeOutSearch = (val:string) => {
    if( timerSearch ) clearTimeout( timerSearch );
    timerSearch = setTimeout(() => { setSearch(val) }, 500)
  }

  const setQuantityCallback = (value: string) => {
    setQuantity(value);
    localStorage.setItem('table-quantity-literature', value);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSort = useCallback((sort: string) => {
    if (sort === 'По дате добавления') {
      setSort('date');
    } else if (sort === 'По алфавиту') {
      setSort('alphabet');
    }
  }, []);

  const closeModal = useCallback(() => {
    setModalData(null);
  }, []);

  const handleRemoveClick = (literature: TLiterature) => {
    setModalData(literature);
  };

  const remove = (_id: string) => {
    dispatch(removeLiteratureThunk(_id, +quantity * page, +quantity, search, sort));
    setModalData(null);
  };

 

  return (
    <Layout title="Общий список литературы" user={user}>
      <Paper header title="Общий список литературы"
        headerContainer={
          <div className={styles['header-container']}>
            <div className={styles['search-container']}>
              <input
                onChange={(event) => setTimeOutSearch(event.currentTarget.value)}
                className={styles['search-input']}
                placeholder="Поиск литературы"
              />
            </div>
            <Link to="/literature/add">
              <Button size="large" className={styles['header-button']}>
                Добавить
              </Button>
            </Link>
          </div>
        }
      >
        {
          (loading.list || loading.size) ?
          <Loader className={styles.loader} /> : <></>
        }
        {
          (list && size) ?
            <Table
              header={[
                'Название',
                'Авторы',
                '',
                <Dropdown
                  list={sortData}
                  onChange={handleSort}
                  direction="down"
                />,
              ]}
              sizes="9fr 5fr 0fr 3fr"
              quantity={quantity}
              setQuantity={setQuantityCallback}
              length={size}
              onPageChange={onPageChange}
              page={page}
            >
              {
                list.map(
                  item =>
                  <Table.Row key={item._id} sizes='9fr 5fr 1fr 2fr'>
                    <div className={styles.name}>
                      <span className={styles.text} >
                        { item.title }
                      </span>
                    </div>
                    <div>
                      {
                        item.author.map(
                          (item, i) =>
                            <div key={i}>
                              <span key={item} className={styles.text}>
                                {item}
                              </span>
                            </div>
                        )
                      }
                    </div>
                    <div className={styles.buttons}>
                      { item.pdfVersionPath &&
                        <a href={ process.env.REACT_APP_BASE_URL + `/files/literature/${item.pdfVersionPath}`}
                          target='_blank'
                        >
                          <Icon type="pdf" />
                        </a>
                      }
                      { item.fullTextLink &&
                        <a href={ `${item.fullTextLink}` } 
                          target='_blank'
                        >
                          <Icon type="pdf-blue" />
                        </a>
                      }

                    </div>
                    <div className={styles.buttons}>
                      <Link to={`/literature/edit/${item._id}`}>
                        <button>
                          <Icon type="edit" />
                        </button>
                      </Link>
                      <button onClick={() => handleRemoveClick(item)}>
                        <Icon type="trash" />
                      </button>
                    </div>
                  </Table.Row>
                )
              }
            </Table>
            : <></>
        }
        {
          (errors.list || errors.size) ?
          <p className={styles['error-message']}>{errors.list}</p>
          : <></>
        }
      </Paper>
      {
        modalData ?
          <Modal
            close={closeModal}
            title="Удаление литературы"
          >
            <div className={styles['modal-content']}>
              <div className={styles['modal-text']}>
                <p>
                  {modalData.title}
                </p>
                <span>
                  Вы действительно хотите<br/>
                  безвозвратно удалить эту литературу?
                </span>
              </div>
              <div className={styles['modal-buttons']}>
                <Button onClick={() => remove(modalData._id)} size="large">Подтвердить</Button>
                <Button color="black" onClick={closeModal} size="large">Отменить</Button>
              </div>
            </div>
          </Modal>
          : <></>
      }
    </Layout>
  );
}

export default LiteratureList;