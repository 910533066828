import { useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks/useStore';
import { authSelector } from '../slices/auth';
import fetchUserData from '../thunks/fetchUserData';
import { TAuthProfile } from '../../types/auth';

interface IUseAuthResult {
  isAuth: boolean;
  getUserData: (token: string) => void;
  error: string;
  loading: boolean;
  user: TAuthProfile | null;
}

function useAuth(): IUseAuthResult {
  const { isAuth, loading, error, user } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();

  const getUserData = useCallback((token: string) => {
    dispatch(fetchUserData(token));
  }, [dispatch]);

  return { isAuth, getUserData, user, error, loading };
}

export default useAuth;
