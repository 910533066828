import { useState, SyntheticEvent } from 'react';
import { useAuth, useForm } from '../../Services/hooks';
import EmailValidator from 'email-validator';
import imageSrc from '../../images/Сибирская.svg';
import { useAppDispatch } from '../../Services/hooks/useStore';
import loginThunk from '../../Services/thunks/login';

import { Input, Button } from '../../Components';
import styles from './Login.module.css';

function Login() {
  const [inputType, setInputType] = useState<boolean>(true);
  const { values, handleChange, errors, setErrors } = useForm(
    { email: '', password: '', },
    { emailError: '', passwordError: '' },
  );
  const dispatch = useAppDispatch();
  
  const token = localStorage.getItem('token');
  const { loading, error } = useAuth();

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    let errors = {
      email: '',
      password: '',
    };
    const { email, password } = values;
    
    if (!EmailValidator.validate(email)) {
      errors.email = 'Введите валидный Email';
    }

    if (!password.length) {
      errors.password = 'Введите пароль';
    } else if (password.length < 6 || password.length > 16) {
      errors.password = 'Введите корректный пароль, от 6 до 16 символов';
    }

    if (errors.email || errors.password) {
      return setErrors({
        emailError: errors.email,
        passwordError: errors.password,
      });
    }

    dispatch(loginThunk(email, password));
  }

  return (
    <main className={styles.container}>
      {
        !token &&
        <section className={styles['login-container']}>
          <h2 className={styles['login-title']}>Авторизация</h2>
          <span className={styles['login-subtitle']}>Панель администратора</span>
          <form className={styles.form} onSubmit={handleSubmit}>
            <Input
              label="Email"
              name="email"
              value={values.email}
              handleChange={handleChange}
              error={errors.emailError}
            />
            <Input
              label="Пароль"
              type={inputType ? 'password' : 'text'}
              name="password"
              icon="eye"
              value={values.password}
              handleChange={handleChange}
              iconClickHandler={() => setInputType(!inputType)}
              className={styles.input}
              error={errors.passwordError}
            />
            {
              error &&
              <span className={styles['error-message']}>{error}</span>
            }
            <Button
              type="submit"
              color="primary"
              size="large"
              className={styles.button}
              disabled={loading}
            >
              Войти
            </Button>
          </form>
        </section>
      }
      {
        !token &&
        <section>
          <h1 className={styles.title}>
            <img src={imageSrc} alt="Сибирская" />
            <span className={styles['title-text']}>
              Академическая наука в лицах
            </span>
          </h1>
        </section>
      }
    </main>
  );
}

export default Login;
