import { TAuthProfile } from '../../types/auth';

import Loader from '../Loader/Loader';
import { Nav, Header, Main } from './Components';
import styles from './Layout.module.css';

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  user: TAuthProfile | null;
  headerContent?: JSX.Element | JSX.Element[];
}

function Layout(props: ILayoutProps) {
  const { children, title, user, headerContent } = props;

  if (user === null) {
    return (
      <div className={styles.loading}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Nav />
      <Header title={title} user={user} content={headerContent} />
      <Main>
        {children}
      </Main>
    </div>
  );
}

export default Layout;
