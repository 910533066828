import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import LiteratureApi from '../../API/LiteratureApi';
import { create, createSuccess, createFail } from '../slices/literatures';
import { TLiteratureCreater } from '../../types/literature';

function createLiterature(literature: TLiteratureCreater, pdfFile: File | null): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(create());

    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await LiteratureApi.createLiterature(literature, token);
        // console.log(result);
        
        if (result.status === 200) {
          // console.log('Литература создана');
          
          const { _id } = result.data;
          
          if (pdfFile !== null) {
            try {
              const result = await LiteratureApi.uploadPdfVersion(_id, pdfFile, token);

              // console.log(result);
              // console.log('pdf версия загруженна');
            } catch(error) {
              dispatch(createFail('Ошибка загрузки основной фотографии'));
            }
          }
        }
        dispatch(createSuccess())
      } catch(error) {
        dispatch(createFail('Ошибка создания персоны'));
      }
    }
  };
}

export default createLiterature;