import { memo, useCallback, useState, useRef, FormEvent, useEffect } from 'react';
import imageSrc from '../../../../images/no-gallery-image.png';
import { TGalleryEditData, TGalleryItem } from '../../../../types/person';
import axios from '../../../../axios';

import { Button, Icon, Input, Modal, TextArea } from '../../../../Components';
import styles from './Gallery.module.css';

type TRemoveData = {
  id: string;
  user: string;
};

interface IGalleryProps {
  gallery: TGalleryEditData[];
  setGallery: (files: TGalleryEditData[]) => void;
  id: string;
}

function Gallery(props: IGalleryProps) {
  const { gallery, setGallery, id } = props;
  const inputPhotoRef = useRef<HTMLInputElement>(null);
  const firstPhotoRef = useRef<HTMLImageElement>(null);
  const secondPhotoRef = useRef<HTMLImageElement>(null);
  const thirdPhotoRef = useRef<HTMLImageElement>(null);

  const [isOpen, setOpen] = useState<boolean>(true);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<TRemoveData | null>(null);
  const [editModal, setEditModal] = useState<TRemoveData | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [editDescription, setEditDescription] = useState('');

  useEffect(() => {
    if (firstPhotoRef.current && gallery[0]) {
      if (gallery[0].file instanceof File) {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (firstPhotoRef.current && typeof reader.result === 'string') {
            firstPhotoRef.current.src = reader.result;
          }
        };

        reader.readAsDataURL(gallery[0].file);
      } else {
        firstPhotoRef.current.src = `${process.env.REACT_APP_BASE_URL}/files/person/${id}/${gallery[0].file}`;
      }
    }

    if (secondPhotoRef.current && gallery[1]) {
      if (gallery[1].file instanceof File) {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (secondPhotoRef.current && typeof reader.result === 'string') {
            secondPhotoRef.current.src = reader.result;
          }
        };

        reader.readAsDataURL(gallery[1].file);
      } else {
        secondPhotoRef.current.src = `${process.env.REACT_APP_BASE_URL}/files/person/${id}/${gallery[1].file}`;
      }
    }

    if (thirdPhotoRef.current && gallery[2]) {
      if (gallery[2].file instanceof File) {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (thirdPhotoRef.current && typeof reader.result === 'string') {
            thirdPhotoRef.current.src = reader.result;
          }
        };

        reader.readAsDataURL(gallery[2].file);
      } else {
        thirdPhotoRef.current.src = `${process.env.REACT_APP_BASE_URL}/files/person/${id}/${gallery[2].file}`;
      }
    }
  }, [gallery, id]);

  const close = useCallback(() => {
    setOpenModal(false);
    setRemoveModal(null);
    setEditModal(null);
    setDescription('');
    setEditDescription('');
    setFile(null);
  }, []);

  const setPhotoFromModal = () => {
    if (inputPhotoRef.current && inputPhotoRef.current.files) {
      setFile(inputPhotoRef.current.files[0]);
    }
  };

  const handleChangeDescription = (event: FormEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setDescription(value);
  }

  const addPhotoToGallery = useCallback(() => {
    if ((inputPhotoRef.current && inputPhotoRef.current.files && inputPhotoRef.current.files.length) && description) {
      const formData = new FormData();
      formData.append('image_person_adv', inputPhotoRef.current.files[0]);
      formData.append('text', description);

      axios.put(`/api/person/setGalleryItem/${id}/imgHrefAdv${gallery.length + 1}`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then(({ data }) => {
          setLoading(false);
          close();

          const newGallery: TGalleryEditData[] = data.gallery.map((item: TGalleryItem) => ({
            file: item.href,
            description: item.text,
            id: item.id,
          }));
          setGallery(newGallery);
        })
        .catch(({ response }) => {
          close();
        });
    }
  }, [description, close, gallery.length, id, setGallery]);

  const openModalRemovePhoto = (photo: TGalleryEditData) => {
    const data: TRemoveData = {
      id: photo.id,
      user: id
    };
    setRemoveModal(data);
  };

  const removePhoto = async (data: TRemoveData) => {
    setLoading(true);
    axios.delete(`/api/person/deleteGalleryItem/${data.user}/${data.id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        close();
        const newGallery: TGalleryEditData[] = data.gallery.map((item: TGalleryItem) => ({
          file: item.href,
          description: item.text,
          id: item.id,
        }));
        setGallery(newGallery);
      })
      .catch(() => {
        close();
      });
  };

  const openEditPhoto = (photo: TGalleryEditData) => {
    const data: TRemoveData = {
      id: photo.id,
      user: id
    };
    setEditDescription(photo.description);
    setEditModal(data);
  }

  const editDescriptionText = async (data: TRemoveData) => {
    setLoading(true);
    axios.put(`/api/person/setGalleryItem-text/${data.user}/${data.id}`, {
      text: editDescription,
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        close();
        const newGallery: TGalleryEditData[] = data.gallery.map((item: TGalleryItem) => ({
          file: item.href,
          description: item.text,
          id: item.id,
        }));
        setGallery(newGallery);
      })
      .catch(() => {
        setLoading(false);
        close();
      });
  };

  return (
    <section className={styles.container}>
      <div className={styles['title-container']}>
        <div className={styles['title-inner-container']} onClick={() => setOpen(isOpen => !isOpen)}>
          <h2 className={styles.title}>Галерея</h2>
          <button className={`${styles['hide-button']} ${!isOpen && styles['reverse-button']}`}><Icon type="caret" /></button>
        </div>
        {gallery.length < 3 && <button className={styles['add-button']} onClick={() => setOpenModal(true)}>Добавить изображение</button>}
      </div>
      <div className={`${styles['gallery-container']} ${!isOpen && styles['gallery-hide']}`}>
        {
          gallery[0] ?
            <div className={styles['gallery-item']}>
              <div className={styles['photo-container-without-padding']}>
                <img
                  src=""
                  alt={gallery[0].description}
                  ref={firstPhotoRef}
                  className={styles['photo']}
                />
              </div>
              <span className={styles['photo-description']}>{gallery[0].description}</span>
              <button className={styles['remove-button']} onClick={() => openModalRemovePhoto(gallery[0])}>
                <Icon type="trash" />
              </button>
              <button className={styles['edit-button']} onClick={() => openEditPhoto(gallery[0])}>
                <Icon type="edit" />
              </button>
            </div> :
            <div className={styles['photo-container']}>
              <img src={imageSrc} alt="" className={styles['no-photo']} />
            </div>
        }
        {
          gallery[1] ?
            <div className={styles['gallery-item']}>
              <div className={styles['photo-container-without-padding']}>
                <img
                  src=""
                  alt={gallery[1].description}
                  ref={secondPhotoRef}
                  className={styles['photo']}
                />
              </div>
              <span className={styles['photo-description']}>{gallery[1].description}</span>
              <button className={styles['remove-button']} onClick={() => openModalRemovePhoto(gallery[1])}>
                <Icon type="trash" />
              </button>
              <button className={styles['edit-button']} onClick={() => openEditPhoto(gallery[1])}>
                <Icon type="edit" />
              </button>
            </div> :
            <div className={styles['photo-container']}>
              <img src={imageSrc} alt="" className={styles['no-photo']} />
            </div>
        }
        {
          gallery[2] ?
            <div className={styles['gallery-item']}>
              <div className={styles['photo-container-without-padding']}>
                <img
                  src=""
                  alt={gallery[2].description}
                  ref={thirdPhotoRef}
                  className={styles['photo']}
                />
              </div>
              <span className={styles['photo-description']}>{gallery[2].description}</span>
              <button className={styles['remove-button']} onClick={() => openModalRemovePhoto(gallery[2])}>
                <Icon type="trash" />
              </button>
              <button className={styles['edit-button']} onClick={() => openEditPhoto(gallery[2])}>
                <Icon type="edit" />
              </button>
            </div>
            :
            <div className={styles['photo-container']}>
              <img src={imageSrc} alt="" className={styles['no-photo']} />
            </div>
        }
      </div>
      {
        isOpenModal &&
        <Modal title="Добавление изображения" close={close}>
          <label className={styles['input-file-wrapper']}>
            <span className={styles['input-file-label']}>Изображение</span>
            <div className={styles['input-file-container']}>
              <span>
                {file ? file.name : 'Выберите файл'}
              </span>
              <input
                className={styles['input-file']}
                type="file"
                onChange={() => setPhotoFromModal()}
                ref={inputPhotoRef}
              />
              <Icon type="upload" className={styles['input-file-icon']} />
            </div>
          </label>
          <label className={styles['input-textarea-wrapper']}>
            <span className={styles['input-file-label']}>Описание</span>
            <div className={styles['input-file-container']}>
              <textarea
                value={description}
                onChange={handleChangeDescription}
                className={styles.textarea}
              />
            </div>
          </label>
          <div className={styles['buttons-container']}>
            <Button
              color="primary"
              size="large"
              onClick={addPhotoToGallery}
            >
              Добавить
            </Button>
            <Button
              color="black"
              size="large"
              onClick={close}
            >
              Отменить
            </Button>
          </div>
        </Modal>
      }
      {
        removeModal &&
        <Modal title="Удаление фотографии" close={close}>
          <p className={styles['remove-photo-text']}>
            Вы действительно хотите удалить данную фотографию?<br />
            Отменить это действие будет невозможно.
          </p>
          <div className={styles['remove-photo-buttons-container']}>
            <Button
              color="primary"
              size="large"
              onClick={() => removePhoto(removeModal)}
              disabled={loading}
            >
              Подтвердить
            </Button>
            <Button
              color="black"
              size="large"
              onClick={close}
              disabled={loading}
            >
              Назад
            </Button>
          </div>
        </Modal>
      }
      {
        editModal &&
        <Modal title="Редактирование описания фотографии" close={close}>
          <TextArea
            label="Описание фотографии"
            value={editDescription}
            handleChange={(event) => setEditDescription(event.currentTarget.value)}
            name="edit-description"
            className={styles['edit-description-field']}
          />
          <div className={styles['edit-photo-buttons-container']}>
            <Button
              color="primary"
              size="large"
              onClick={() => editDescriptionText(editModal)}
              disabled={loading}
            >
              Подтвердить
            </Button>
            <Button
              color="black"
              size="large"
              onClick={close}
              disabled={loading}
            >
              Отмена
            </Button>
          </div>
        </Modal>
      }
    </section>
  );
}

export default memo(Gallery);
