import { Routes, Route, useLocation } from 'react-router-dom';
import { useInitialApp } from '../../Services/hooks';

import { LoginPage, PersonsListPage, PersonsAddPage, LiteratureListPage, LiteratureAddPage,
  UserListPage, UserEditPage, UserAddPage, PersonEditPage, LiteratureEditPage,
} from '../../Pages';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';

function App() {
  useInitialApp();

  const location = useLocation();
  
  return (
    <Routes location={location}>
      <Route path="/login" element={<ProtectedRoute anonymous element={<LoginPage />} />} />
      <Route path="/" element={<ProtectedRoute element={<PersonsListPage />} />} />
      <Route path="/person/add" element={<ProtectedRoute element={<PersonsAddPage />} />} />
      <Route path="/person/edit/:id" element={<ProtectedRoute element={<PersonEditPage />} />} />
      <Route path="/literature" element={<ProtectedRoute element={<LiteratureListPage />} />} />
      <Route path="/literature/add" element={<ProtectedRoute element={<LiteratureAddPage />} />} />
      <Route path="/literature/edit/:id" element={<ProtectedRoute element={<LiteratureEditPage />} />} />
      <Route path="/user" element={<ProtectedRoute element={<UserListPage />} />} />
      <Route path="/user/add" element={<ProtectedRoute element={<UserAddPage />} />} />
      <Route path="/user/edit/:username" element={<ProtectedRoute element={<UserEditPage />} />} />
    </Routes>
  );
}

export default App;
