import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import PersonsApi from '../../API/PersonsApi';
import { fetchPerson, fetchPersonSuccess, fetchPersonFail } from '../slices/persons';

function fetchPersonThunk(_id: string): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetchPerson());

    try {
      const result = await PersonsApi.fetchPerson(_id);

      if (result.status === 200) {
        dispatch(fetchPersonSuccess(result.data));
      }
    } catch(error) {
      dispatch(fetchPersonFail('Ошибка загрузки данных'));
    }
  }
}

export default fetchPersonThunk;