import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import UserApi from '../../API/UserApi';
import { fetchUser, fetchUserSuccess, fetchUserFail } from '../slices/users';

function fetchPersonThunk(username: string): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetchUser());
    const token = localStorage.getItem('token');

    try {
      const result = await UserApi.getUserByUsername(username, token);
      
      if (result.status === 200) {
        dispatch(fetchUserSuccess( result.data ));
      }
    } catch(error) {
      dispatch(fetchUserFail('Ошибка загрузки данных'));
    }
  }
}

export default fetchPersonThunk;