import { memo } from 'react';
import { letters } from './letters';

import styles from './Alphabet.module.css';

interface IAlphabetProps {
  letter: string;
  onClick: (value: string) => void;
}

function Alphabet(props: IAlphabetProps) {
  const { letter, onClick } = props;

  return (
    <div className={styles.container}>
      <div className={styles.letters}>
        {
          letters.map(
            item =>
              <span
                key={item}
                className={`${styles.letter} ${letter === item && styles.active}`}
                onClick={() => onClick(item)}
              >
                {item}
              </span>
          )
        }
      </div>
    </div>
  );
}

export default memo(Alphabet);