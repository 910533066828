import { useCallback, useState, useEffect, FormEvent, useRef } from 'react';
import { useAuth, useForm, } from '../../Services/hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Services/hooks/useStore';

import { literatureSelector, createEnd, changeStatus } from '../../Services/slices/literatures';
import createLiterature from '../../Services/thunks/createLiterature';

import { Button, Input, Layout, Modal, Paper, Loader, Icon } from '../../Components';
import MainInfo from './Components/MainInfo/MainInfo';
import styles from './LiteratureEdit.module.css';
import { TLiterature, TLiteratureCreater } from '../../types/literature';
import editLiteratureThunk from '../../Services/thunks/editLiterature';
import fetchLiteratureListThunk from '../../Services/thunks/fetchLiteratureList';
import removeLiteratureFileThunk from '../../Services/thunks/removeLiteratureFile';

function LiteratureEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { list, loading, status } = useAppSelector(literatureSelector);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const form = useForm({
    title: '',
    link: '',
  }, {
    titleError: '',
    linkError: '',
  });

  const [literatue, setLiterature] = useState<TLiterature | null>(null);
  const [authors, setAuthors] = useState<{value: string; id: number}[]>([]);

  const saveLiterature = useCallback(() => {
    const { values, setErrors, errors } = form;

    if (values.title === "") {
      return setErrors({
        ...errors,
        titleError: 'Название не может быть пустым',
      });
    }

    if (id) {
      dispatch(editLiteratureThunk(id, values.title, values.link, authors.map(item => item.value), file));
    }
  }, [form, dispatch, id, authors, file]);

  const close = useCallback(() => {
    dispatch(changeStatus({ name: 'isSuccessEdit', value: false }));
  }, [dispatch]);

  const handleChangeAuthor = (id: number, event: FormEvent<HTMLInputElement>) => {
    const author = authors.find(item => item.id === id);

    if (author) {
      author.value = event.currentTarget.value;

      setAuthors([...authors]);
    }
  };

  const addAuthor = () => {
    setAuthors([...authors, { value: "", id: Math.random() }]);
  };

  const removeAuthor = (id: number) => {
    const filteredAuthors = authors.filter(item => item.id !== id);
    setAuthors(filteredAuthors);
  };

  const upload = () => {
    if (inputRef.current && inputRef.current.files && inputRef.current.files[0]) {
      setFile(inputRef.current.files[0]);
    }
  };

  const removeFile = () => {
    if (literatue) {
      removeLiteratureFileThunk(literatue._id);
    }
  };

  // Загрузка списка литературы
  useEffect(() => {
    if (list === null && !loading.list) {
      dispatch(fetchLiteratureListThunk(0, 999999));
    }
  }, [list, loading.list, dispatch]);

  // Поиск и установка дефолтных значений
  useEffect(() => {
    if (list && !literatue) {
      const { setValue } = form;
      const currentLiterature = list.find(item => item._id === id);

      if (currentLiterature) {
        setLiterature(currentLiterature);
        setValue(['title', 'link'], [currentLiterature.title, currentLiterature.fullTextLink]);
        const authors = currentLiterature.author.map(item => ({ value: item, id: Math.random() }));
        setAuthors(authors);
      } else {
        navigate('/literature');
      }
    }
  }, [form, id, list, literatue, navigate]);

  return (
    <Layout 
      title="Добавление литературы" 
      user={user}
      headerContent={<div className={
        styles['header-content']}>
          <Button
            color="primary"
            size="large"
            className={styles['header-button']}
            onClick={saveLiterature}
          >
            <span>
              Сохранить
            </span>
          </Button>
          <Link to="/literature">
            <Button color="red" size="large">Отменить</Button>
          </Link>
          
        </div>
      }
    >
      {
        list === null ?
        <div className={styles.loader}>
          <Loader />
        </div>
        : <></>
      }
      {
        literatue ?
          <div className={styles['papers-grid']}>
            <Paper className={styles['paper-container']}>
              <h2 className={styles['paper-title']}>Документ</h2>
              <div className={styles['fields-grid']}>
                <Input
                  value={form.values.title}
                  handleChange={form.handleChange}
                  name="title"
                  className={styles['title-field']}
                  label="Название"
                  required
                  error={form.errors.titleError}
                />
                <div className={styles['file-buttons-container']}>
                  <label htmlFor="file" className={styles['label-button']}>
                    Загрузить новый PDF-файл
                  </label>
                  {
                    literatue.pdfVersionPath && <Button onClick={removeFile}>Удалить PDF</Button>
                  }
                  <input
                    type="file"
                    className={styles['file-field']}
                    id="file"
                    onChange={upload}
                    ref={inputRef}
                    accept='.pdf'
                  />
                </div>
                <Input
                  value={form.values.link}
                  handleChange={form.handleChange}
                  name="link"
                  className={styles['pdf-field']}
                  label="Ссылка на полный текст"
                />
              </div>
            </Paper>
            <Paper className={styles['paper-container']}>
              {/* <div className={styles['paper-header']}>
                <h2 className={styles['paper-title']}>Авторы</h2>
                <button
                  className={styles['add-button-author']}
                  onClick={addAuthor}
                >
                  Добавить пункт
                </button>
              </div>
              <div className={styles['authors-list']}>
                {
                  authors.map(
                    (item, i) =>
                      <div key={item.id} className={styles['author-input-container']}>
                        <Input
                          value={authors[i].value}
                          handleChange={(event) => handleChangeAuthor(item.id, event)}
                          className={styles['author-input']}
                        />
                        <button onClick={() => removeAuthor(item.id)}>
                          <Icon type="cancel"/>
                        </button>
                      </div>
                  )
                }
              </div> */}
            </Paper>
          </div>
          : <></>
      }
      {
        status.isSuccessEdit ?
        <Modal title="" close={close}>
          <p className={styles['modal-message']}>Изменения успешно сохранены!</p>
          <div className={styles['modal-buttons']}>
            <Button size="large" onClick={close}>Продолжить</Button>
            <Link to="/literature">
              <Button size="large" color="black" onClick={close}>
                К списку литературы
              </Button>
            </Link>
          </div>
        </Modal>
        : <></>
      }
    </Layout>
  );
}

export default LiteratureEdit;
