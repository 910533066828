import Icon from '../Icon/Icon';
import Paginate  from 'react-paginate';
import Dropdown from '../Dropdown/Dropdown';
import styles from './Table.module.css';

interface ITableProps {
  header: Array<string | JSX.Element>;
  children: JSX.Element[];
  sizes: string;
  quantity: string;
  setQuantity: (value: string) => void;
  length: number;
  onPageChange: (page: number) => void;
  page: number;
}

interface IRowProps {
  children: JSX.Element | JSX.Element[];
  sizes: string;
}

function Table(props: ITableProps) {
  const { header, children, sizes, quantity, setQuantity, length, onPageChange, page } = props;

  return (
    <section>
      <div className={styles.header} style={{ gridTemplateColumns: sizes }}>
        {
          header.map(
            (item, i) =>
              <div key={i}>
                {
                  typeof item === 'string' ?
                    <span>{item}</span> : item
                }
              </div>
          )
        }
      </div>
      <div className={styles.body}>
        {
          children.map( item => item )
        }
      </div>
      <div className={styles.footer}>
        <div className={styles['dropdown-container']}>
          <span>Отобразить:</span>
          <Dropdown
            defaultValue={quantity}
            list={['5', '10', '15']}
            onChange={(value) => setQuantity(value)}
            className={styles.dropdown}
            direction="up"
          />
        </div>
        <Paginate
          pageCount={Math.ceil(length / +quantity)}
          onPageChange={({ selected }) => onPageChange(selected)}
          containerClassName={styles['pagination-container']}
          pageClassName={styles['pagination-page']}
          activeClassName={styles['pagination-active']}
          previousLabel={<Icon type="caret" className={`${styles['pagination-button']}`} />}
          nextLabel={<Icon type="caret" className={`${styles['pagination-button']} ${styles['pagination-next']}`} />}
          forcePage={page}
        />
      </div>
    </section>
  );
}

Table.Row = (props: IRowProps) => (
  <div className={styles.row} style={{ gridTemplateColumns: props.sizes }}>
    {props.children}
  </div>
);

export default Table;
