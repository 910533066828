import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import AuthApi from '../../API/AuthApi';
import { fetch, success, fail } from '../slices/auth';
import { TAuthProfile } from '../../types/auth';

function loginThunk(email: string, password: string): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetch());
    
    try {
      const result = await AuthApi.fetchLogin(email, password);

      if (result.status === 200) {
        const { access_token, ...rest } = result.data;

        const data: TAuthProfile = { ...rest };

        dispatch(success(data));
        localStorage.setItem('token', access_token);
      } else {
        dispatch(fail('Неверный Email или пароль'));
      }
    } catch(error) {
      dispatch(fail('Ошибка загрузки данных'));
    }
  }
}

export default loginThunk;