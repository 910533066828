import { useState, FormEvent } from 'react';
import Icon, { TIcon } from '../Icon/Icon';

import styles from './Input.module.css';

interface IInputProps {
  className?: string;
  label?: string;
  type?: 'text' | 'email' | 'password' | 'date' | 'number' | 'file';
  icon?: TIcon;
  iconClickHandler?: () => void;
  value: string;
  handleChange: (event: FormEvent<HTMLInputElement>) => void;
  name?: string;
  error?: string;
  placeholder?: string;
  required?: boolean;
}

function Input(props: IInputProps) {
  const {
    className,
    label,
    type,
    icon,
    iconClickHandler,
    value,
    handleChange,
    name,
    error,
    placeholder,
    required,
  } = props;
  const [focus, setFocus] = useState<boolean>(false);

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  return (
    <label className={`${styles['root-container']} ${className}`}>
      {label && 
        <span className={styles.label}>{label} {required && <span className={styles.required}>*</span>}</span>
      }
      <div className={`${styles.container} ${focus ? styles.focus : ''} ${error ? styles['error-border'] : ''}`}>
        <input
          className={styles.input}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
          name={name}
          placeholder={placeholder}
        />
        {
          icon &&
          <button
            type="button"
            className={`${styles.icon} ${iconClickHandler ? styles['clickable-icon'] : ''}`}
            onClick={iconClickHandler}
          >
            <Icon type={icon} />
          </button>
        }
      </div>
      {
        error &&
        <span className={styles['error-text']}>{error}</span>
      }
    </label>
  );
}

export default Input;
