import styles from './Icon.module.css';

import addSrc from '../../images/icons/add.svg';
import addBlueSrc from '../../images/icons/add-blue.svg';
import calendarSrc from '../../images/icons/calendar.svg';
import cancel from '../../images/icons/cancel.svg';
import checkboxOffSrc from '../../images/icons/checkbox-off.svg';
import checkboxOnSrc from '../../images/icons/checkbox-on.svg';
import documentSrc from '../../images/icons/document.svg';
import editSrc from '../../images/icons/edit.svg';
import expandSrc from '../../images/icons/expand.svg';
import eyeSrc from '../../images/icons/eye.svg';
import logoSrc from '../../images/icons/logo.svg';
import logoutSrc from '../../images/icons/logout.svg';
import pdfSrc from '../../images/icons/pdf.svg';
import pdfBlueSrc from '../../images/icons/pdf-blue.svg';
import personsSrc from '../../images/icons/persons.svg';
import trashSrc from '../../images/icons/trash.svg';
import universitySrc from '../../images/icons/university.svg';
import uploadSrc from '../../images/icons/upload.svg';
import downSrc from '../../images/icons/down.svg';
import caretSrc from '../../images/icons/caret.svg';
import closeSrc from '../../images/icons/close.svg';


export type TIcon =
  | 'add'
  | 'add-blue'
  | 'calendar'
  | 'cancel'
  | 'checkbox-off'
  | 'checkbox-on'
  | 'document'
  | 'edit'
  | 'expand'
  | 'eye'
  | 'logo'
  | 'logout'
  | 'pdf'
  | 'pdf-blue'
  | 'persons'
  | 'trash'
  | 'university'
  | 'upload'
  | 'down'
  | 'caret'
  | 'close';

interface IIconProps {
  className?: string;
  type: TIcon;
  alt?: string;
  onClick?: () => void;
}

function Icon(props: IIconProps) {
  const { type, alt, className, onClick } = props;
  let icon: string;

  switch (type) {
    case 'add':
      icon = addSrc; break;
    case 'add-blue':
      icon = addBlueSrc; break;
    case 'calendar':
      icon = calendarSrc; break;
    case 'cancel':
      icon = cancel; break;
    case 'checkbox-off':
      icon = checkboxOffSrc; break;
    case 'checkbox-on':
      icon = checkboxOnSrc; break;
    case 'document':
      icon = documentSrc; break;
    case 'edit':
      icon = editSrc; break;
    case 'expand':
      icon = expandSrc; break;
    case 'eye':
      icon = eyeSrc; break;
    case 'logo':
      icon = logoSrc; break;
    case 'logout':
      icon = logoutSrc; break;
    case 'pdf':
      icon = pdfSrc; break;
    case 'pdf-blue':
      icon = pdfBlueSrc; break;
    case 'persons':
      icon = personsSrc; break;
    case 'trash':
      icon = trashSrc; break;
    case 'university':
      icon = universitySrc; break;
    case 'upload':
      icon = uploadSrc; break;
    case 'down':
      icon = downSrc; break;
    case 'caret':
      icon = caretSrc; break;
    case 'close':
      icon = closeSrc; break;
  }

  return (
    <img
      src={icon}
      alt={alt || ''}
      className={`${styles.icon} ${className}`}
      onClick={onClick}
    />
  );
}

export default Icon;
