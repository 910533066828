import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootStore } from "../../store";

interface ISystemState {
  redirect: string;
}

const initialState: ISystemState = {
  redirect: '',
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    changeRedirect: (state, action: PayloadAction<string>) => {
      state.redirect = action.payload;
    },
  },
});

export const { changeRedirect } = systemSlice.actions;

export const systemSelector = (state: TRootStore) => state.system;

export default systemSlice.reducer;
