import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Services/hooks';
import { useAppDispatch, useAppSelector } from '../../Services/hooks/useStore';

import removeUserThunk from '../../Services/thunks/removeUser';
import fetchUserList from '../../Services/thunks/fetchUserList'
import { userSelector } from '../../Services/slices/users'
import { TUser } from '../../types/user';

import { Layout, Paper, Loader, Icon, Table, Button, Modal } from '../../Components';
import styles from './UserList.module.css';

function UserList(){
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { loading, size, errors, list } = useAppSelector(userSelector);
  const [quantity, setQuantity] = useState<string>(localStorage.getItem('table-quantity-user') || '10');
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [modalData, setModalData] = useState<TUser | null>(null);

  useEffect(() => {
    dispatch(fetchUserList( +quantity * page, +quantity, search));
  }, [dispatch, quantity, page, search])

  let timerSearch: number | null | NodeJS.Timeout = null
  const setTimeOutSearch = (val:string) => {
    if( timerSearch ) clearTimeout( timerSearch )
    timerSearch = setTimeout(() => { setSearch(val) }, 500)
  }

  const setQuantityCallback = (value: string) => {
    setQuantity(value)
    localStorage.setItem('table-quantity-user', value);
  }

  const onPageChange = (page: number) => {
    setPage(page);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const closeModal = useCallback(() => {
    setModalData(null);
  }, [])

  const handleRemoveClick = (user: any) => { 
    setModalData(user);
  }

  const remove = (username: string) => {
    dispatch(removeUserThunk(username, +quantity * page, +quantity, search));
    setModalData(null);
  }

  return (
  <Layout title="Общий список пользователей" user={user}>
    <Paper header title="Общий список пользователей" 
      headerContainer={
        <div className={styles['header-container']}>
          <div className={styles['search-container']}>
            <input
              onChange={(event) => setTimeOutSearch(event.currentTarget.value)}
              className={styles['search-input']}
              placeholder="Поиск персоны по фио"
            />
          </div>
          <Link to="/user/add">
            <Button size="large" className={styles['header-button']}>
              Создать
            </Button>
          </Link>
        </div>
      }
    >
      {
        (loading.list || loading.size) ?
        <Loader className={styles.loader} /> : <></>
      }
      {
        (list && size) ?
          <Table
            header={[
              'Имя',
              'Email',
              'Роль',
              '',
            ]}
            sizes="10fr 6fr 6fr 2fr"
            quantity={quantity}
            setQuantity={setQuantityCallback}
            length={size}
            onPageChange={onPageChange}
            page={page}
          >
            {
              list.filter(el => el.username !== "admin@gmail.com").map(
                item =>
                <Table.Row key={item.username} sizes='10fr 6fr 6fr 2fr'>
                  <div className={styles.name}>
                    <span className={styles.text} >
                      { `${item.name.first} ${item.name.middle} ${item.name.last}` }
                    </span>
                  </div>
                  <div>
                    { item.username }
                  </div>
                  <div>
                    { item.role }
                  </div>
                  <div className={styles.buttons}>
                    <Link to={`/user/edit/${item.username}`} state={{ page: page }}>
                      <button>
                        <Icon type="edit" />
                      </button>
                    </Link>
                    <button onClick={() => handleRemoveClick(item)}>
                      <Icon type="trash" />
                    </button>
                  </div>
                </Table.Row>
              )
            }
          </Table>
          : <></>
      }
      {
        (errors.list || errors.size) ?
        <p className={styles['error-message']}>{errors.list}</p>
        : <></>
      }
    </Paper>
    {
      modalData ?
        <Modal
          close={closeModal}
          title="Удаление литературы"
        >
          <div className={styles['modal-content']}>
            <div className={styles['modal-text']}>
              <p>
                {`${modalData.name.first} ${modalData.name.middle} ${modalData.name.last}`}
              </p>
              <span>
                Вы действительно хотите<br/>
                безвозвратно удалить этого пользователя?
              </span>
            </div>
            <div className={styles['modal-buttons']}>
              <Button onClick={() => remove(modalData.username)} size="large">Подтвердить</Button>
              <Button color="black" onClick={closeModal} size="large">Отменить</Button>
            </div>
          </div>
        </Modal>
        : <></>
    }
    </Layout>
  )
}

export default UserList