import styles from './Paper.module.css';

interface IPaperProps {
  children?: JSX.Element | JSX.Element[] | string | null;
  className?: string;
  header?: boolean;
  headerContainer?: JSX.Element | JSX.Element[] | string;
  title?: string;
}

function Paper(props: IPaperProps) {
  const { children, className, header, headerContainer, title } = props;

  return (
    <article className={`${styles.container} ${className}`}>
      {
        header &&
        <header className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          { headerContainer }
        </header>
      }
      {children}
    </article>
  );
}

export default Paper;
