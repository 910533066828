import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import LiteratureApi from '../../API/LiteratureApi';
import { create, createSuccess, createFail, fetchList, fetchListSuccess, changeStatus } from '../slices/literatures';
import { TLiteratureCreater } from '../../types/literature';

function createLiteratureAndUpdate(literature: TLiteratureCreater, pdfFile: File | null, literatureType?: 'mainLiterature' | 'aboutLiterature' | 'additionalyLiterature'): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(create());

    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await LiteratureApi.createLiterature(literature, token);
        
        if (result.status === 200) {
          
          const { _id } = result.data;
          
          if (pdfFile !== null) {
            try {
              const result = await LiteratureApi.uploadPdfVersion(_id, pdfFile, token);

            } catch(error) {
              dispatch(createFail(''));
            }
          }

          try {
            dispatch(fetchList());
            const params = {
              skip: 0, limit: 999999,
            };
            const result = await LiteratureApi.fetchList(params);
            if (result.status === 200) {
              dispatch(fetchListSuccess(result.data));
              dispatch(createSuccess());
              if (literatureType) {
                dispatch(changeStatus({ name: literatureType, value: _id }));
              }
            }
          } catch(error) {
            createFail('Ошибка обновления списка литературы. Обновите страницу');
          }
        }


      } catch(error) {
        dispatch(createFail('Ошибка создания персоны'));
      }
    }
  };
}

export default createLiteratureAndUpdate;