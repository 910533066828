import { useAuth, useForm, } from '../../Services/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Services/hooks/useStore';

import { literatureSelector, createEnd } from '../../Services/slices/literatures';
import createLiterature from '../../Services/thunks/createLiterature';

import { Button, Layout, Modal } from '../../Components';
import MainInfo from './Components/MainInfo/MainInfo';
import styles from './LiteratureAdd.module.css';
import { TLiteratureCreater } from '../../types/literature';


function LiteratureAdd() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(literatureSelector);
  const [pdfVersion, setPdfVersion] = useState<File | null>(null);
  const [modalData, setModalData] = useState<string | null>(null);
  const [authors, setAuthors] = useState<string[]>(['']);
  const [propsErrors, setPropsErrors] = useState<any>({
    pdfVersionError:''
  });

  const hendlerSetPdfVersion = (file:any) => {
    let fileNameArr = file.name.split('.')
    let format = fileNameArr[fileNameArr.length - 1 ]
    if( format === 'pdf') setPropsErrors({...propsErrors,pdfVersionError:''});
    setPdfVersion(file)
  }
  const mainInfoFormData = useForm({
    title: '',
    fullTextLink: '',
  }, {
    titleError: '',
    fullTextLinkError: '',
  });

  const saveLiterature = useCallback(()=>{
    let errors = {
      titleError: '',
      pdvVersionError:'',
    };
    const { values: mainValues, setErrors: setMainErrors } = mainInfoFormData;

    if (!mainValues.title) {
      errors.titleError = 'Необходимо заполнить все обязательные поля';
    }
    if( pdfVersion ){
      let fileNameArr = pdfVersion.name.split('.')
      let format = fileNameArr[fileNameArr.length - 1 ]
      if( format !== 'pdf'){
        errors.pdvVersionError = 'Формат файла должен быть .pdf'
        setPropsErrors({
          ...propsErrors,
          pdfVersionError:'Формат файла должен быть .pdf' 
        })
      }
    }
    if (errors.titleError || errors.pdvVersionError) {
      return setMainErrors(errors);
    }

    const literature: TLiteratureCreater = {
      title: mainInfoFormData.values.title,
      author: authors,
      yearOut: -1,
      pageNumber: -1,
      fullTextLink: mainInfoFormData.values.fullTextLink,
    }

    dispatch(createLiterature( literature, pdfVersion ))

  }, [mainInfoFormData, pdfVersion, dispatch])

  //modal succes
  const navigate = useNavigate();
  useEffect(()=>{
    if(status.isSuccesCreate) setModalData('Пункт успешно добвален');
  },[status.isSuccesCreate])

  const closeModal = () => {
    setModalData(null);
    dispatch(createEnd())
    navigate('/literature')
  };

  const formData:any = {
    mainInfoFormData,
    authors,
    pdfVersion
  }

  return (
    <Layout 
      title="Добавление литературы" 
      user={user}
      headerContent={<div className={
        styles['header-content']}>
          <Button
            color="primary"
            size="large"
            className={styles['header-button']}
            onClick={saveLiterature}
          >
            <span>
              Добавить пункт
            </span>
          </Button>
          <Link to="/literature">
            <Button color="red" size="large">Отменить</Button>
          </Link>
          
        </div>
      }
    >
      <MainInfo 
        formData={formData} 
        setPdfVersion={hendlerSetPdfVersion} 
        setAuthors={setAuthors}
        errors={ propsErrors }
      />
      {
        modalData ?
        <Modal
          close={closeModal}
          title="Cоздание литературы"
        >
          <div className={styles['modal-content']}>
            <div className={styles['modal-text']}>
              <p>
                Литература успешно добавленна
              </p>
            </div>
            <div className={styles['modal-buttons']}>
              <Button onClick={closeModal} size="large">Продолжить</Button>
            </div>
          </div>
        </Modal>
        : <></>
      }
    </Layout>
  );
}

export default LiteratureAdd;