import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import LiteratureApi from '../../API/LiteratureApi';
import { remove, removeSuccess, removeFail } from '../slices/literatures';
import fetchLiteratureList from './fetchLiteratureList';

function removeLiteratureThunk(_id:string, skip: number, limit:number, title?: string, srort?: string): ThunkAction<void, TRootStore, unknown, AnyAction>{
  return async dispatch => {
    dispatch(remove());
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await LiteratureApi.removeLiterature(_id, token);
  
        if (result.status === 200) {
          dispatch(removeSuccess());
          dispatch(fetchLiteratureList(skip, limit, title, srort));
        }
      } catch(error) {
        dispatch(removeFail('Ошибка удаления литературы'));
      }
    }
  }
}

export default removeLiteratureThunk;