import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootStore } from "../../store";
import { TAuthProfile } from '../../types/auth';

interface IAuthState {
  user: TAuthProfile | null;
  isAuth: boolean;
  loading: boolean;
  error: string;
}

const initialState: IAuthState = {
  user: null,
  isAuth: false,
  loading: false,
  error: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
      state.error = '';
    },
    success: (state, action: PayloadAction<TAuthProfile>) => {
      state.loading = false;
      state.isAuth = true;
      state.user = action.payload;
    },
    fail: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    logout: (state) => {
      state.isAuth = false;
      state.user = null;
    },
  },
});

export const { fetch, success, fail, logout } = authSlice.actions;

export const authSelector = (state: TRootStore) => state.auth;

export default authSlice.reducer;
