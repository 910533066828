import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import UserApi from '../../API/UserApi';
import { create, createSuccess, createFail } from '../slices/users';
import { TUserCreate } from '../../types/user';

function createUser(user: TUserCreate): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(create());

    const token = localStorage.getItem('token');

    if (token) {
      try {
        console.log('createUser');
        
        const result = await UserApi.createUser(user, token);
        dispatch(createSuccess())
      } catch(error) {
        dispatch(createFail('Ошибка создания персоны'));
      }
    }
  };
}

export default createUser;