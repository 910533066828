import { memo, useCallback, useState, useRef, FormEvent, useEffect } from 'react';
import imageSrc from '../../../../images/no-gallery-image.png';
import { TGalleryData } from '../../../../types/person';

import { Button, Icon, Modal } from '../../../../Components';
import styles from './Gallery.module.css';

interface IGalleryProps {
  gallery: TGalleryData[];
  setGallery: (files: TGalleryData[]) => void;
}

function Gallery(props: IGalleryProps) {
  const { gallery, setGallery } = props;
  const inputPhotoRef = useRef<HTMLInputElement>(null);
  const firstPhotoRef = useRef<HTMLImageElement>(null);
  const secondPhotoRef = useRef<HTMLImageElement>(null);
  const thirdPhotoRef = useRef<HTMLImageElement>(null);

  const [isOpen, setOpen] = useState<boolean>(true);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (firstPhotoRef.current && gallery[0]) {
      const reader  = new FileReader();
      
      reader.onloadend = () => {
        if (firstPhotoRef.current && typeof reader.result === 'string') {
          firstPhotoRef.current.src = reader.result;
        }
      };

      reader.readAsDataURL(gallery[0].file);
    }

    if (secondPhotoRef.current && gallery[1]) {
      const reader  = new FileReader();
      
      reader.onloadend = () => {
        if (secondPhotoRef.current && typeof reader.result === 'string') {
          secondPhotoRef.current.src = reader.result;
        }
      };

      reader.readAsDataURL(gallery[1].file);
    }

    if (thirdPhotoRef.current && gallery[0]) {
      const reader  = new FileReader();
      
      reader.onloadend = () => {
        if (thirdPhotoRef.current && typeof reader.result === 'string') {
          thirdPhotoRef.current.src = reader.result;
        }
      };

      reader.readAsDataURL(gallery[2].file);
    }
  }, [gallery]);

  const close = useCallback(() => {
    setOpenModal(false);
    setDescription('');
    setFile(null);
  }, []);

  const setPhotoFromModal = () => {
    if (inputPhotoRef.current && inputPhotoRef.current.files) {
      setFile(inputPhotoRef.current.files[0]);
    }
  };

  const handleChangeDescription = (event: FormEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setDescription(value);
  }

  const addPhotoToGallery = useCallback(() => {
    if ((inputPhotoRef.current && !inputPhotoRef.current.files) || !description) {
      return;
    }

    if (inputPhotoRef.current && inputPhotoRef.current.files) {
      const galleryItem: TGalleryData = {
        file: inputPhotoRef.current.files[0],
        description,
      };
      
      setGallery([...gallery, galleryItem]);
      close();
    }
  }, [description, gallery, setGallery, close]);

  const removePhoto = (photo: TGalleryData) => {
    setGallery(gallery.filter(item => item.file !== photo.file));
  };

  return (
    <section className={styles.container}>
      <div className={styles['title-container']}>
        <div className={styles['title-inner-container']} onClick={() => setOpen(isOpen => !isOpen)}>
          <h2 className={styles.title}>Галерея</h2>
          <button className={`${styles['hide-button']} ${!isOpen && styles['reverse-button']}`}><Icon type="caret" /></button>
        </div>
        {gallery.length < 3 && <button className={styles['add-button']} onClick={() => setOpenModal(true)}>Добавить изображение</button>}
      </div>
      <div className={`${styles['gallery-container']} ${!isOpen && styles['gallery-hide']}`}>
        {
          gallery[0] ? 
          <div className={styles['gallery-item']}>
            <div className={styles['photo-container-without-padding']}>
              <img
                src=""
                alt={gallery[0].description}
                ref={firstPhotoRef}
                className={styles['photo']}
              />
            </div>
            <span className={styles['photo-description']}>{gallery[0].description}</span>
              <button className={styles['remove-button']} onClick={() => removePhoto(gallery[0])}>
                <Icon type="trash" />
              </button>
          </div> :
            <div className={styles['photo-container']}>
              <img src={imageSrc} alt="" className={styles['no-photo']} />
            </div>
        }
        {
          gallery[1] ?
            <div className={styles['gallery-item']}>
              <div className={styles['photo-container-without-padding']}>
                <img
                  src=""
                  alt={gallery[1].description}
                  ref={secondPhotoRef}
                  className={styles['photo']}
                />
              </div>
              <span className={styles['photo-description']}>{gallery[1].description}</span>
              <button className={styles['remove-button']} onClick={() => removePhoto(gallery[1])}>
                <Icon type="trash" />
              </button>
            </div> :
            <div className={styles['photo-container']}>
              <img src={imageSrc} alt="" className={styles['no-photo']} />
            </div>
        }
        {
          gallery[2] ?
            <div className={styles['gallery-item']}>
              <div className={styles['photo-container-without-padding']}>
                <img
                  src=""
                  alt={gallery[2].description}
                  ref={thirdPhotoRef}
                  className={styles['photo']}
                />
              </div>
              <span className={styles['photo-description']}>{gallery[2].description}</span>
              <button className={styles['remove-button']} onClick={() => removePhoto(gallery[2])}>
                <Icon type="trash" />
              </button>
            </div>
             :
            <div className={styles['photo-container']}>
              <img src={imageSrc} alt="" className={styles['no-photo']} />
            </div>
        }
      </div>
      {
        isOpenModal &&
        <Modal title="Добавление изображения" close={close}>
          <label className={styles['input-file-wrapper']}>
            <span className={styles['input-file-label']}>Изображение</span>
            <div className={styles['input-file-container']}>
              <span>
                {file ? file.name : 'Выберите файл'}
              </span>
              <input
                className={styles['input-file']}
                type="file"
                onChange={() => setPhotoFromModal()}
                ref={inputPhotoRef}
              />
              <Icon type="upload" className={styles['input-file-icon']} />
            </div>
          </label>
          <label className={styles['input-textarea-wrapper']}>
            <span className={styles['input-file-label']}>Описание</span>
            <div className={styles['input-file-container']}>
              <textarea
                value={description}
                onChange={handleChangeDescription}
                className={styles.textarea}
              />
            </div>
          </label>
          <div className={styles['buttons-container']}>
            <Button
              color="primary"
              size="large"
              onClick={addPhotoToGallery}
            >
              Добавить
            </Button>
            <Button
              color="black"
              size="large"
              onClick={close}
            >
              Отменить
            </Button>
          </div>
        </Modal>
      }
    </section>
  );
}

export default memo(Gallery);
