import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import PersonsApi from '../../API/PersonsApi';
import { create, createSuccess, createFail } from '../slices/persons';
import { changeRedirect } from '../slices/system';
import { TGalleryData, TPersonForCreate } from '../../types/person';

function createPerson(person: TPersonForCreate, photo: File | null, gallery: TGalleryData[]): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(create());

    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await PersonsApi.createPerson(person, token);

        console.log(result);
        

        if (result.status === 201) {
          const { _id } = result.data;
          
          if (photo !== null) {
            try {
              await PersonsApi.uploadAvatar(_id, photo, token);
            } catch(error) {
              dispatch(createFail('Ошибка загрузки основной фотографии'));
            }
          }

          if (gallery.length) {
            for (let i = 0; i < gallery.length; i++) {
              await PersonsApi.uploadPhoto(_id, gallery[i].file, gallery[i].description, `imgHrefAdv${i + 1}`, token);
            }
          }

          dispatch(changeRedirect(`/person/edit/${result.data._id}`));
          dispatch(createSuccess());
        }
      } catch(error) {
        dispatch(createFail('Ошибка создания персоны'));
      }
    }
  };
}

export default createPerson;
