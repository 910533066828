import axios from '../axios';
import { TAxiosResult } from '../types/axios';
import { TRegaliaListResult } from '../types/regalia';

class RegaliasApi {
  static async fetchRegaliasList(): Promise<TAxiosResult<TRegaliaListResult>> {
    return await axios.get('/api/regalia/list')
      .catch(({ response }) => ({ ...response }));
  }
}

export default RegaliasApi;
