import { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Services/hooks/useStore';
import fetchPersonThunk from '../../Services/thunks/fetchPerson';
import editPersonThunk from '../../Services/thunks/editPerson';
import { personsSelector, clearPerson, changeStatus } from '../../Services/slices/persons';
import { useAuth, useForm } from '../../Services/hooks';
import { regaliasSelector } from '../../Services/slices/regalias';
import fetchRegaliasList from '../../Services/thunks/fetchRefaliasList';
import { TPersonRegalia, TGalleryEditData } from '../../types/person';

import { Photo, MainInfo, LidInfo, MainText, Literature, Gallery } from './Components';
import { Layout, Paper, Loader, Button, Modal } from '../../Components';
import styles from './PersonEdit.module.css';

function PersonEdit() {
  const dispatch = useAppDispatch();
  const { person, loading, errors, status } = useAppSelector(personsSelector);
  const { user } = useAuth();
  const location = useLocation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const { list: regaliasList, loading: regaliasLoading } = useAppSelector(regaliasSelector);
  const mainInfoFormData = useForm({
    lastName: '',
    firstName: '',
    middleName: '',
    birthday: '',
    deathday: '',
    description: '',
  }, {
    lastNameError: '',
    firstNameError: '',
    middleNameError: '',
    birthdayError: '',
    deathdayError: '',
    descriptionError: '',
  });

  const locationPage = location.state?.page || null;

  // Photo
  const [photo, setPhoto] = useState<File | string | null>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);

  // Загрузка персоны
  useEffect(() => {
    if (id) {
      if (!person) {
        dispatch(fetchPersonThunk(id));
      }
    }
  }, [id, dispatch, person, loading.person]);

  useEffect(() => {
    if (person && !loading.person) {
      setLoading(false);
    }
  }, [person, loading.person]);

  // Установка фото если оно есть
  useEffect(() => {
    if (person && person.imgHref && !photo) {
      setPhoto(person.imgHref);
    }
  }, [person]); // eslint-disable-line

  // Заполнение main info
  useEffect(() => {
    if (person && !isLoading) {
      const { setValue } = mainInfoFormData;

      setValue(
        [ 'lastName', 'firstName', 'middleName', 'birthday', 'deathday', 'description' ],
        [ person.name.last, person.name.first, person.name.middle, person.birthday, person.deathday, person.description ],
      );
    }
  }, [person, isLoading]); // eslint-disable-line

  // Loading Regalias
  useEffect(() => {
    dispatch(fetchRegaliasList());
  }, [dispatch]);

  // Lid Info
  const regaliaText = useForm({
    personScienceRegaliaAdvText: '',
  }, {
    personScienceRegaliaAdvTextError: '',
  });
  const [regalias, setRegalias] = useState<TPersonRegalia[]>([]);
  const [regaliaError, setRegaliaError] = useState<string>('');
  
  
  useEffect(() => {
    if (person && !isLoading) {
      regaliaText.setValue(['personScienceRegaliaAdvText'], [person.personScienceRegaliaAdvText]);
      setRegalias(person.personScienceRegalia);
    }
  }, [person, isLoading]); // eslint-disable-line
  
  const upload = () => {
    const reader  = new FileReader();

    if(avatarInputRef.current && avatarInputRef.current.files) {
      const file = avatarInputRef.current.files['0'];

      reader.onloadend = () => {
        if (avatarRef.current && typeof reader.result === 'string') {
          
          avatarRef.current.src = reader.result;
        }
      };

      if (file) {
        reader.readAsDataURL(file);
        setPhoto(file);
      } else {
        setPhoto(null);
      }
    }
  }

  // Main Text
  const [mainText, setMainText] = useState<string>('');
  useEffect(() => {
    if (person && !isLoading) {
      setMainText(person.mainText);
    }
  }, [person, isLoading]); // eslint-disable-line

  // Literatures
  // Main literature
  const [mainLiteratureList, setMainLiteratureList] = useState<string[]>([]);
  
  // About literature
  const [aboutLiteratureList, setAboutLiteratureList] = useState<string[]>([]);

  // Additionally literature
  const [additionallyLiteratureList, setAdditionallyLiteratureList] = useState<string[]>([]);

  useEffect(() => {
    if (person && !isLoading) {
      setMainLiteratureList(person.literatureOfPerson);
      setAboutLiteratureList(person.literatureAboutPerson);
      setAdditionallyLiteratureList(person.personAdvInfo);
    }
  }, [person, isLoading]); // eslint-disable-line

  // Gallery
  const [gallery, setGallery] = useState<TGalleryEditData[]>([]);

  useEffect(() => {
    if (person && !isLoading) {
      const newGallery: TGalleryEditData[] = person.gallery.map(item => ({
        file: item.href,
        description: item.text,
        id: item.id,
      }));
      setGallery(newGallery);
    }
  }, [person, isLoading]); // eslint-disable-line

  // SAVE PERSON
  const savePerson = useCallback(() => {
    const person = {
      name: {
        last: mainInfoFormData.values.lastName,
        first: mainInfoFormData.values.firstName,
        middle: mainInfoFormData.values.middleName,
      },
      birthday: mainInfoFormData.values.birthday,
      deathday: mainInfoFormData.values.deathday,
      description: mainInfoFormData.values.description,
      personScienceRegaliaAdvText: regaliaText.values.personScienceRegaliaAdvText,
      personScienceRegalia: regalias,
      mainText: mainText,
      literatureOfPerson: mainLiteratureList,
      literatureAboutPerson: aboutLiteratureList,
      personAdvInfo: additionallyLiteratureList,
    };
    if (id) {
      dispatch(editPersonThunk(id, person, photo));
    }
  }, [
    dispatch, mainInfoFormData.values, id, regalias,
    regaliaText.values.personScienceRegaliaAdvText,
    aboutLiteratureList, additionallyLiteratureList,
    mainLiteratureList, photo, mainText,
  ]);

  const completed = useCallback(() => {
    dispatch(changeStatus({ field: 'isSuccessEdit', value: false }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearPerson());
    };
  }, [dispatch]);

  return (
    <Layout
      title="Редактирование персоны"
      user={user}
      headerContent={
        <div className={styles['header-content']}>
          <Button
            color="primary"
            size="large"
            className={styles['header-button']}
            onClick={savePerson}
          >
            Сохранить изменения
          </Button>
          <Link to="/" state={{ page: locationPage }}>
            <Button color="red" size="large">Отменить</Button>
          </Link>
        </div>
      }
    >
        {
          loading.person || regaliasLoading.list ?
          <Loader className={styles.loader} /> : <></>
        }
        {
          errors.person ?
          <p className={styles['error-message']}>{errors.person}</p> : <></>
        }
        {
          person && regaliasList ?
          <Paper className={styles.grid}>
            <Photo
              hasPhoto={!!photo}
              setPhoto={setPhoto}
              inputRef={avatarInputRef}
              imgRef={avatarRef}
              photo={(person.imgHref && photo) ? `${process.env.REACT_APP_BASE_URL}/files/person/${person._id}/${person.imgHref}` : ""}
            />
            <input
              type="file"
              className={styles['file-upload-input']}
              ref={avatarInputRef}
              onChange={upload}
              id="photo-upload"
            />
            <div>
              <MainInfo formData={mainInfoFormData} />
              <LidInfo
                list={regalias}
                setRegalias={setRegalias}
                regalias={regaliasList}
                regaliaText={regaliaText.values.personScienceRegaliaAdvText}
                regaliaTextError={regaliaText.errors.personScienceRegaliaAdvTextError}
                setRegaliaText={regaliaText.handleChange}
                regaliasError={regaliaError}
              />
              <MainText
                value={mainText}
                handleChange={setMainText}
              />
              <Literature
                title="Избранные труды"
                literatureList={mainLiteratureList}
                setLiteratureList={setMainLiteratureList}
                literatureType="mainLiterature"
              />
              <Literature
                title="Литература о жизни и деятельности"
                literatureList={aboutLiteratureList}
                setLiteratureList={setAboutLiteratureList}
                literatureType="aboutLiterature"
              />
              <Literature
                title="Дополнительные материалы"
                literatureList={additionallyLiteratureList}
                setLiteratureList={setAdditionallyLiteratureList}
                literatureType="additionalyLiterature"
              />
              <Gallery
                gallery={gallery}
                setGallery={setGallery}
                id={person._id}
              />
            </div>
          </Paper> : <></>
        }
        
      { loading.edit || status.isSuccessEdit ?
        <Modal title="Редактирование персоны" close={() => console.log(123)}>
          <div className={styles['modal-content']}>
            <span className={styles['modal-name']}>
              {mainInfoFormData.values.lastName} {mainInfoFormData.values.firstName} {mainInfoFormData.values.middleName}
            </span>
            {
              loading.edit &&
              <>
                <span className={styles['modal-notifiction']}>
                  Сохранение изменений...
                </span>
                <div className={styles['modal-loader-container']}>
                  <Loader className={styles['modal-loader']} />
                </div>
              </>
            }
            {
              status.isSuccessEdit &&
              <>
                <span className={styles['modal-notifiction']}>
                  Персона успешно Сохранена!
                </span>
                <Link to="#" className={styles['modal-button-link']} state={{ page: locationPage }}>
                  <Button size="large" className={styles['modal-button']} onClick={completed}>
                    Продолжить
                  </Button>
                </Link>
              </>
            }
          </div>
        </Modal> : <></>
      }
    </Layout>
  );
}

export default PersonEdit;
