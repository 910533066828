import { useState, useCallback, FormEvent, SetStateAction } from 'react';

export interface IUseFormResult {
  values: TValues;
  handleChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setValue: (field: string[], value: string[]) => void;
  errors: TErrors;
  setErrors: (value: SetStateAction<TErrors>) => void;
  resetForm: (newValues?: TValues, newErrors?: TErrors) => void;
}

type TValues = {
  [key: string]: string;
};

type TErrors = {
  [key: string]: string;
};

function useForm(defaultValues: TValues = {}, defaultErrors: TErrors = {}): IUseFormResult {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState(defaultErrors);

  const handleChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;

    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [`${name}Error`]: '' });
  };

  const setValue = (fields: string[], values: string[]) => {
    const newValues: { [key: string]: string } = {};
    
    fields.forEach((field, i) => {
      newValues[field] = values[i];
    });
    setValues({ ...newValues });
  };

  const resetForm = useCallback((newValues: TValues = {}, newErrors: TErrors = {}) => {
    setValues(newValues);
    setErrors(newErrors);
  }, [setValues, setErrors]);

  return { values, handleChange, errors, setErrors, resetForm, setValue };
}

export default useForm;
