import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import PersonsApi from '../../API/PersonsApi';
import { remove, removeSuccess, removeFail } from '../slices/persons';
import fetchPersonsList from './fetchPersonsList';

function removePerson(_id: string, skip: number, limit: number, name?: string, filter?: string): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(remove());
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const result = await PersonsApi.removePerson(_id, token);
  
        if (result.status === 200) {
          dispatch(removeSuccess());
          dispatch(fetchPersonsList(skip, limit, name, filter));
        }
      } catch(error) {
        dispatch(removeFail('Ошибка удаления персоны'));
      }
    }
  }
}

export default removePerson;