import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootStore } from "../../store";
import { TRegalia } from '../../types/regalia';

interface IRegaliasState {
  list: TRegalia[] | null;
  loading: {
    list: boolean;
  };
  errors: {
    list: string;
  }
}

const initialState: IRegaliasState = {
  list: null,
  loading: {
    list: false,
  },
  errors: {
    list: '',
  },
};

const regaliasSlice = createSlice({
  name: 'regalias',
  initialState,
  reducers: {
    fetchList: (state) => {
      state.loading = {
        ...state.loading,
        list: true,
      };
      state.errors = {
        ...state.errors,
        list: '',
      }
    },
    fetchListSuccess: (state, action: PayloadAction<TRegalia[]>) => {
      state.loading = {
        ...state.loading,
        list: false,
      };
      state.list = action.payload;
    },
    fetchListFail: (state, action: PayloadAction<string>) => {
      state.errors = {
        ...state.errors,
        list: action.payload,
      };
      state.loading = {
        ...state.loading,
        list: false,
      };
    },
  },
});

export const {
  fetchList, fetchListSuccess, fetchListFail,
} = regaliasSlice.actions;

export const regaliasSelector = (state: TRootStore) => state.regalias;

export default regaliasSlice.reducer;
