import { useState, memo } from 'react';
import Icon from '../Icon/Icon';
import styles from './Dropdown.module.css';

interface IDropdownProps {
  list: string[];
  defaultValue?: string;
  onChange: (value: string) => void;
  className?: string;
  direction: 'up' | 'down';
}

function Dropdown(props: IDropdownProps) {
  const { list, defaultValue, onChange, className, direction } = props;
  const [currentValue, setCurrentValue] = useState<string>(defaultValue || list[0]);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleClick = (item: string) => {
    setCurrentValue(item);
    onChange(item);
    setOpen(false);
  };

  return (
    <div className={`${styles.dropdown} ${className}`}>
      <div className={styles.container} onClick={() => setOpen((value) => !value)}>
        <span>{currentValue}</span>
        <div className={`${styles['icon-container']} ${direction === 'up' ? styles['icon-up'] : ''}`}>
          <Icon
            type="down"
            className={`${styles.icon} ${isOpen ? styles['icon-open'] : ''}`}
          />
        </div>
      </div>
      <div className={`${styles.list} ${direction === 'up' ? styles['list-up'] : styles['list-down']} ${isOpen ? styles.show : ''}`}>
        {
          list.map(
            item =>
              <span
                key={item}
                className={styles['list-item']}
                onClick={() => handleClick(item)}
              >
                {item}
              </span>
          )
        }
      </div>
    </div>
  );
}

export default memo(Dropdown);
