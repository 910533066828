import styles from './Main.module.css';

interface IMainProps {
  children: JSX.Element | JSX.Element[];
}

function Main(props: IMainProps) {
  const { children } = props;

  return (
    <main className={styles.container}>
      {children}
    </main>
  );
}

export default Main;
