import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootStore } from '../../store';
import AuthApi from '../../API/AuthApi';
import { fetch, success, fail } from '../slices/auth';

function fetchUserData(token: string): ThunkAction<void, TRootStore, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const result = await AuthApi.fetchUserData(token);

      if (result.status === 200) {
        dispatch(success(result.data));
      } else {
        localStorage.removeItem('token');
      }
    } catch (error) {

      localStorage.removeItem('token');
      dispatch(fail('Ошибка загрузки данных'));
    }
  }
}

export default fetchUserData;